import mapValues from 'lodash/mapValues'
import { LabelValue, transformCarColors } from '../utils/dataFormat'
interface HeaderItem {
  icon: string
  link: string
  title: string
}

interface MinEstimPercentType {
  step1?: number
  step2?: number
  step3?: number
  step4?: number
  step5?: number
}

export default class Config {
  public TVA: number
  public header: HeaderItem[]
  public minEstimPercent: MinEstimPercentType
  public legal: string
  public defaultBrands: string[]
  public colors: LabelValue[]
  public formUrl: string
  public minEstimPrice: number
  public covidBanner?: boolean
  public displayRefunding: boolean
  public DisplayRefundingOption: boolean

  constructor(values: any) {
    this.TVA = Number(values.TVA)
    this.header = Object.values(values.header).map(
      ({ label, url, icon }: any) => ({
        icon,
        link: url,
        title: label
      })
    )
    this.minEstimPercent = mapValues(values.minEstimPercent, item =>
      parseFloat(item)
    )
    this.legal = values.legalTerms
    this.defaultBrands = Object.values(values.defaultBrands)

    this.colors = [
      ...transformCarColors(values.colors.primary),
      ...transformCarColors(values.colors.secondary)
    ]
    this.formUrl = values.Form_URL

    this.minEstimPrice = values.MinEstimPrice
    try {
      this.covidBanner = JSON.parse(values.covidBanner)
    } catch {
      this.covidBanner = false
    }
    try {
      this.displayRefunding = JSON.parse(values.displayRefunding)
    } catch {
      this.displayRefunding = true
    }
    try {
      this.DisplayRefundingOption = JSON.parse(values.DisplayRefundingOption)
    } catch {
      this.DisplayRefundingOption = false
    }
  }
}
