import moment from 'moment'
import { assign, EventObject, StateNodeConfig } from 'xstate'

import { Context } from '.'
import Routes from '../../constants/Routes'
import { postLeadsTradIn } from '../../services/api'
import { clear } from '../sync'

const GLOBAL_OFFERS = '#app.demand.state.offers'
const GLOBAL_FAILURE = '#app.failure'

const APPOINTMENT_DATE = moment()
  .add(15, 'days')
  .format('DD/MM/YYYY 09:00:00')

export interface ContactSchema {
  states: {
    appointment: {}
    appointmentInformations: {}
    phone: {}
    email: {}
    confirmation: {}
  }
}

export interface ContactEvent extends EventObject {
  type: 'NEXT' | 'PREVIOUS' | 'FILLED'
}

export enum ContactTypes {
  APPOINMENT_INFORMATION = 'appointmentInformations',
  APPOINTMENT = 'appointment',
  PHONE = 'phone',
  EMAIL = 'email'
}

export enum ConfirmationTypes {
  APPOINTMENT = 'appointment',
  PHONE = 'phone',
  EMAIL = 'email'
}

// tslint:disable:object-literal-sort-keys
function setUserInformation(
  requestedType: ConfirmationTypes,
  appointmentDate?: string
) {
  return assign(
    (
      ctx: Context,
      { sCiviliteClient, sNomClient, sPrenomClient, sEmailClient, phone_number }
    ) => ({
      demand: {
        ...ctx.demand,
        confirmationType: requestedType,
        userInformations: {
          ...ctx.demand.userInformations,
          sCiviliteClient,
          sNomClient,
          sPrenomClient,
          sEmailClient,
          phone_number,
          requested_for: appointmentDate
            ? appointmentDate
            : ctx.demand.userInformations!.requested_for
        }
      }
    })
  )
}

/**
 * This is the State Machine part related to contact forms
 */
const node: StateNodeConfig<any, ContactSchema, ContactEvent> = {
  initial: 'appointment',
  states: {
    appointment: {
      meta: { path: Routes.ContactAppointment },
      on: {
        PREVIOUS: GLOBAL_OFFERS,
        NEXT: {
          target: 'appointmentInformations',
          actions: assign((ctx, { requested_for }) => ({
            demand: {
              ...ctx.demand,
              userInformations: {
                ...ctx.demand.userInformations,
                requested_for
              }
            }
          }))
        }
      }
    },
    appointmentInformations: {
      meta: { path: Routes.ContactAppointmentInformations },
      on: {
        PREVIOUS: 'appointment',
        NEXT: {
          target: 'confirmation',
          actions: setUserInformation(ConfirmationTypes.APPOINTMENT)
        }
      }
    },
    phone: {
      meta: { path: Routes.ContactPhone },
      on: {
        PREVIOUS: GLOBAL_OFFERS,
        NEXT: {
          target: 'confirmation',
          actions: setUserInformation(ConfirmationTypes.PHONE, APPOINTMENT_DATE)
        }
      }
    },
    email: {
      meta: { path: Routes.ContactEmail },
      on: {
        PREVIOUS: GLOBAL_OFFERS,
        NEXT: {
          target: 'confirmation',
          actions: setUserInformation(ConfirmationTypes.EMAIL, APPOINTMENT_DATE)
        }
      }
    },
    confirmation: {
      type: 'final',
      meta: { path: Routes.ContactConfirmation },
      invoke: {
        src: ctx => postLeadsTradIn(ctx.demand),
        onDone: {
          actions: () => {
            clear('demand')
            clear('qp')
          }
        },
        onError: {
          target: GLOBAL_FAILURE,
          actions: 'consoleError'
        }
      }
    }
  },
  on: {
    FILLED: {
      actions: assign((ctx, { isValid, values }) => ({
        demand: { ...ctx.demand, form: { isValid, values } }
      }))
    }
  }
}

export default node
