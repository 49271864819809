import { assign, EventObject, StateNodeConfig } from 'xstate'

import isEmpty from 'lodash/isEmpty'
import { Context, UserCategory } from '.'
import Routes from '../../constants/Routes'
import {
  getOffers,
  getVehicleElements,
  getVehicleOptions,
  setTryOutDemandOffer
} from '../../services/api'
import { Project } from '../../vo/Project'

const GLOBAL_FAILURE = '#app.failure'

function formatExteriorConditionData(data: EventObject) {
  return Object.values(data)
    .flat(2)
    .map(value => {
      if (value !== 'NEXT') {
        return JSON.parse(value)
      }
      return null
    })
}

export interface StateSchema {
  states: {
    color: {}
    details: {}
    fetchElements: {}
    exterior: {}
    tyres: {}
    fetchOptions: {}
    options: {}
    fetchOffers: {}
    fetchEstimateOffers: {}
    offers: {}
  }
}

type StateEventTypes = 'NEXT' | 'PREVIOUS' | 'FILLED' | 'ABORT' | 'REDIRECT'

export interface StateEvent extends EventObject {
  type: StateEventTypes
}

function resetVehicleColor() {
  return assign((ctx: any) => ({
    demand: {
      ...ctx.demand,
      manual: {
        ...ctx.demand.manual,
        sCouleurExt: undefined,
        sCouleurInt: undefined
      }
    }
  }))
}
function resetVehicleManual() {
  return assign((ctx: any) => ({
    demand: {
      ...ctx.demand,
      manual: {}
    }
  }))
}

/**
 * This is the State Machine part related to the car state pages
 */
// tslint:disable:object-literal-sort-keys
const node: StateNodeConfig<any, StateSchema, StateEvent> = {
  initial: 'color',
  states: {
    color: {
      meta: { path: Routes.Colors },
      on: {
        PREVIOUS: [
          {
            target: '#app.demand.manual.fetchEngine',
            cond: ({ demand }) => !demand.vehicle && isEmpty(demand.vehSelect),
            actions: resetVehicleColor()
          },
          {
            target: '#app.demand.startWorkflow.estimateResult',
            cond: ({ demand }) => !isEmpty(demand.vehSelect),
            actions: resetVehicleManual()
          },
          {
            target: '#app.demand.startWorkflow.found',
            cond: ({ demand }) =>
              demand.vehicle &&
              demand.vehicle.s_veh_typnatcode &&
              demand.project !== Project.KNOW_PRICE,
            actions: resetVehicleColor()
          },
          {
            target: '#app.demand.userInformations',
            cond: ({ demand }) =>
              demand.vehicle &&
              demand.vehicle.s_veh_typnatcode &&
              demand.project === Project.KNOW_PRICE,
            actions: resetVehicleColor()
          }
        ],
        NEXT: {
          target: 'details',
          actions: [
            assign((ctx, { sCouleurExt, sCouleurInt }) => ({
              demand: {
                ...ctx.demand,
                manual: {
                  ...ctx.demand.manual,
                  sCouleurExt,
                  sCouleurInt
                }
              }
            }))
          ]
        }
      }
    },
    details: {
      meta: { path: Routes.Details },
      on: {
        PREVIOUS: 'color',
        NEXT: {
          target: 'fetchElements',
          actions: [
            assign((ctx, { bCarnetEntretient, bImport, bPremiereMain }) => ({
              demand: {
                ...ctx.demand,
                manual: {
                  ...ctx.demand.manual,
                  bCarnetEntretient: bCarnetEntretient === 'true',
                  bImport: bImport === 'true',
                  bPremiereMain: bPremiereMain === 'true'
                }
              }
            }))
          ]
        }
      }
    },
    fetchElements: {
      meta: { loading: true },
      invoke: {
        src: ({ demand }) =>
          getVehicleElements(
            demand.vehicle
              ? demand.vehicle.i_veh_maknatcode
              : demand.manual.vehicle.i_veh_maknatcode,
            demand.vehicle
              ? demand.vehicle.s_veh_modname2
              : demand.manual.vehicle.s_veh_modname2
          ),
        onDone: {
          target: 'exterior',
          actions: assign((_, { data }) => ({
            vehicleElements: data
          }))
        },
        onError: GLOBAL_FAILURE
      }
    },
    exterior: {
      meta: { path: Routes.Exterior },
      on: {
        PREVIOUS: 'details',
        NEXT: {
          target: 'tyres',
          actions: [
            assign((ctx, event) => ({
              demand: {
                ...ctx.demand,
                manual: {
                  ...ctx.demand.manual,
                  exteriorCondition: formatExteriorConditionData(event)
                }
              }
            }))
          ]
        }
      }
    },
    tyres: {
      meta: { path: Routes.Tyres },
      on: {
        PREVIOUS: [
          {
            target: 'exterior',
            cond: ctx => ctx.vehicleElements !== undefined
          },
          {
            target: 'fetchElements'
          }
        ],
        NEXT: {
          target: 'fetchOptions',
          actions: assign((ctx, { front, rear }) => ({
            demand: {
              ...ctx.demand,
              manual: {
                ...ctx.demand.manual,
                tyres: {
                  front,
                  rear
                }
              }
            }
          }))
        }
      }
    },
    fetchOptions: {
      meta: { loading: true },
      invoke: {
        src: ({ demand }) =>
          getVehicleOptions(
            1,
            demand.manual.dDateMec
              ? demand.manual.dDateMec
              : demand.manual.vehicle.d_veh_date_mec,
            demand.manual.vehicle.i_veh_maknatcode,
            demand.manual.vehicle.s_veh_modname2,
            demand.manual.vehicle.sTypNatcode
              ? demand.manual.vehicle.sTypNatcode
              : demand.manual.vehicle.s_veh_typnatcode
          ),
        onDone: {
          target: 'options',
          actions: assign((_, { data }) => ({
            vehicleOptions: data.options
          }))
        },
        onError: GLOBAL_FAILURE
      }
    },
    options: {
      meta: { path: Routes.Options },
      on: {
        PREVIOUS: 'tyres',
        NEXT: [
          {
            target: '#app.demand.state.fetchEstimateOffers',
            actions: assign((ctx, { vehicleOptions }) => ({
              demand: {
                ...ctx.demand,
                isAnonymousDemand: !ctx.demand.userInformations.sEmailClient,
                manual: {
                  ...ctx.demand.manual,
                  options: vehicleOptions
                }
              }
            }))
          }
        ]
      }
    },
    fetchEstimateOffers: {
      meta: { loading: true },
      invoke: {
        src: (ctx: Context) => setTryOutDemandOffer(ctx.demand),
        onDone: [
          {
            target: '#app.demand.startWorkflow.estimateResultManual',
            cond: (ctx, { data }) =>
              !!data.dataVehicle.iPrixReprise &&
              ctx.config.minEstimPrice <= data.dataVehicle.iPrixReprise,
            actions: assign((ctx, { data }) => ({
              demand: {
                ...ctx.demand,
                dataVehicle: data.dataVehicle,
                estimation: {
                  ...data.dataVehicle,
                  iPrixReprise:
                    ctx.demand.userCategory === UserCategory.PROFESSIONNEL
                      ? Math.round(
                          data.dataVehicle.iPrixReprise / ctx.config.TVA
                        )
                      : data.dataVehicle.iPrixReprise
                },
                dynamicOffer: data.offer,
                userInformations: {
                  ...ctx.demand.userInformations,
                  i_con_id:
                    data.dataVehicle &&
                    data.dataVehicle.aReprise &&
                    data.dataVehicle.aReprise.i_con_id
                }
              }
            }))
          },
          {
            target: '#app.errorFetchDemand',
            cond: (ctx, { data }) =>
              !data.dataVehicle.iPrixReprise ||
              ctx.config.minEstimPrice > data.dataVehicle.iPrixReprise,
            actions: assign((ctx, { data }) => ({
              demand: {
                ...ctx.demand,
                estimation: {
                  ...data.dataVehicle
                },
                userInformations: {
                  ...ctx.demand.userInformations,
                  i_con_id:
                    data.dataVehicle &&
                    data.dataVehicle.aReprise &&
                    data.dataVehicle.aReprise.i_con_id
                }
              }
            }))
          }
        ],
        onError: GLOBAL_FAILURE
      }
    },
    fetchOffers: {
      meta: { loading: true },
      invoke: {
        src: () => getOffers(),
        onDone: {
          target: 'offers',
          actions: assign((_, { data }) => ({
            offers: data
          }))
        },
        onError: GLOBAL_FAILURE
      }
    },
    offers: {
      meta: { path: Routes.Offers }
    }
  },
  on: {
    FILLED: {
      actions: assign((ctx, { isValid, values }) => ({
        demand: { ...ctx.demand, form: { isValid, values } }
      }))
    },
    ABORT: {
      target: '#app.demand.finance',
      actions: assign((ctx: any) => ({
        demand: {
          ...ctx.demand,
          manual: {},
          vehicleByLicense: [],
          mediaByLicense: []
        }
      }))
    }
  }
}

export default node
