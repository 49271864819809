import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { persist } from '../context/sync'

export function setMBV(mbvString: string) {
  const [, mbv]: any = mbvString.match(/(.+);*.*$/) || []
  if (mbv) {
    persist('MBV', mbv)
  } else {
    persist('MBV', '')
  }
}

export function getRGBColor(vehicleConfigurator: any) {
  const exteriorColor = get(
    vehicleConfigurator,
    'aCouleurExterieureSkoda.ExteriorColors',
    {}
  )
  const bgColorObject = find(exteriorColor, item => item.Code === 'Body')

  return !isEmpty(bgColorObject)
    ? `rgb(${bgColorObject.R}, ${bgColorObject.G}, ${bgColorObject.B})`
    : ''
}
