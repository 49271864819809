import lodashAssign from 'lodash/assign'
import filter from 'lodash/filter'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import keys from 'lodash/keys'
import pick from 'lodash/pick'
import moment from 'moment'
import { Context } from '../context/machines'
import { retrieve } from '../context/sync'
import SkodaVehicle from '../vo/SkodaVehicle'
import VehicleElements, {
  DamageElement,
  VehiclePartEle
} from '../vo/VehicleElements'
import {
  COMMON_FIELD_NAME,
  DATE_FORMAT,
  DATE_FORMAT_FROM_API,
  EXTERIOR_CAR_PART,
  FUNDING_CODE,
  FUNDING_PLAN,
  MOCK_OPTIONS
} from './constants'

const { NODE_ENV } = process.env

export interface LabelValue {
  label: string
  value: string
}

export interface KeyValue<V = string> {
  [key: string]: V
}

/**
 * Returns the transformed given object into a form compliant label/value array
 */
export function transformIntoLabelValue(
  entries: KeyValue | string[]
): LabelValue[] {
  if (entries instanceof Array) {
    return entries.map(value => ({ label: value, value }))
  }

  return Object.entries(entries).map(([value, label]) => ({ label, value }))
}

/**
 * Returns the transformed given object into a form compliant label/value array
 */
export function transformVehiclesIntoLabelValue(vehicles: SkodaVehicle[]) {
  return vehicles.map(({ id, display_name }) => ({
    label: display_name,
    value: id.toString()
  }))
}

/**
 * Returns the transformed given object into a frorm compliant months label/value array
 */
export function transformIntoMonthsLabelValue(entries: string[]): LabelValue[] {
  return entries.map((data, index) => ({
    label: data,
    value: index < 9 ? `0${index + 1}` : `${index + 1}`
  }))
}

/**
 * Returns the transformed given object into a form compliant label/value array with valid color code for the value
 */
export function transformCarColors(colors: KeyValue) {
  return Object.entries(colors).map(([label, color]) => ({
    label,
    value: color.includes('#') ? color : `#${color}`
  }))
}

/**
 * Returns the transformed given object into a zipCode to search the corresponding city
 */
export function transformLocationIntoZipcode(locationObject: any): any {
  const location = locationObject.results.filter((loc: any) =>
    loc.types.includes('postal_code')
  )
  return location[0].address_components.filter((loca: any) =>
    loca.types.includes('locality')
  )[0].long_name
}

/**
 * Returns the value rounded to hundreds
 */
export function roundToHundreds(value: number) {
  return Math.round(value / 100) * 100
}

/**
 * Returns the value with currency formating
 */
export function formatCurrency(value: number, locale = 'fr-FR', opts?: any) {
  const numberFormat = new Intl.NumberFormat(locale, opts)
  return numberFormat.format(value)
}

/**
 * Filter vehicle elements and return one element
 */
export function findVehiclePart(
  parts: VehicleElements[],
  ele: VehiclePartEle
): VehicleElements {
  const vehicleFilterByLabel = Object.values(parts).filter(part =>
    part.s_rub_libelle.includes(ele.label)
  )[0]
  const vehiclePart = vehicleFilterByLabel
    ? vehicleFilterByLabel
    : Object.values(parts).filter(
        part => part.i_rub_zone_cliquable === ele.zoneId
      )[0] || {
        i_rub_id: ele.prodId,
        i_rub_zone_cliquable: ele.zoneId,
        s_rub_libelle: ele.label
      }
  if (NODE_ENV === 'production') {
    vehiclePart.i_rub_id = ele.prodId
  }

  return vehiclePart
}

interface Parts {
  id: number
}

interface VehicleParts {
  [key: string]: Parts[]
}

/**
 * Returns the specifics vehicle part to fit to the screen
 */
// tslint:disable:object-literal-sort-keys
export function transformSpecificsParts(
  parts: VehicleElements[]
): VehicleParts {
  return {
    front: [
      {
        id: 1,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.front[0])
      },
      {
        id: 2,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.front[1])
      },
      {
        id: 3,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.front[2])
      },
      {
        id: 4,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.front[3])
      },
      {
        id: 5,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.front[4])
      }
    ],
    rear: [
      {
        id: 1,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rear[0])
      },
      {
        id: 2,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rear[1])
      }
    ],
    leftSide: [
      {
        id: 1,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.leftSide[0])
      },
      {
        id: 2,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.leftSide[1])
      },
      {
        id: 3,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.leftSide[2])
      },
      {
        id: 4,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.leftSide[3])
      },
      {
        id: 5,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.leftSide[4])
      }
    ],
    rightSide: [
      {
        id: 1,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rightSide[0])
      },
      {
        id: 2,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rightSide[1])
      },
      {
        id: 3,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rightSide[2])
      },
      {
        id: 4,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rightSide[3])
      },
      {
        id: 5,
        ...findVehiclePart(parts, EXTERIOR_CAR_PART.rightSide[4])
      }
    ]
  }
}

/**
 * Returns the specifics vehicle parts to get the initial values for the form
 */
export function transformExteriorPartstoInitialValues(parts: DamageElement[]) {
  return parts.reduce((acc: KeyValue<string[]>, element: DamageElement) => {
    if (element) {
      acc[element.s_lde_damage_areas_libelle] = [JSON.stringify(element)]
    }
    return acc
  }, {})
}

/**
 * Returns the default funding plan base on the availableFunding data
 */

export function getStep() {
  const demand = retrieve('demand') || {}
  return get(demand, 'step', 0)
}
export function getFundingPlan(availableFunding: any) {
  if (!isEmpty(availableFunding)) {
    return resetFundingPlan(availableFunding)
  }
  const demand = retrieve('demand') || {}
  const plan = get(demand, 'finance.plan', '')
  if (!!plan) {
    return plan
  }
  return resetFundingPlan(availableFunding)
}

export function resetFundingPlan(availableFunding: any = {}) {
  if (
    availableFunding.hasOwnProperty(FUNDING_CODE.LEASING) &&
    isValidFundingData(availableFunding[FUNDING_CODE.LEASING])
  ) {
    return FUNDING_PLAN.LEASING
  } else if (
    availableFunding.hasOwnProperty(FUNDING_CODE.RENT) &&
    isValidFundingData(availableFunding[FUNDING_CODE.RENT])
  ) {
    return FUNDING_PLAN.RENT
  } else if (
    availableFunding.hasOwnProperty(FUNDING_CODE.CREDIT) &&
    isValidFundingData(availableFunding[FUNDING_CODE.CREDIT])
  ) {
    return FUNDING_PLAN.CREDIT
  } else {
    return FUNDING_PLAN.LEASING
  }
}

export function minObjectValue(obj: any) {
  return Object.keys(obj).reduce((a, b) => (obj[b] < obj[a] ? b : a))
}
export function maxObjectValue(obj: any) {
  return Object.keys(obj).reduce((a, b) => (obj[b] >= obj[a] ? b : a))
}

export function getContributionParams(
  dataFunding: any,
  dataVehicle: any = {},
  maxContribution: number
) {
  const { insC43FinancementDTO } = dataFunding
  const minContribution =
    Number(insC43FinancementDTO.f_fin_montant_a_financer_min) || 0
  if (
    dataVehicle &&
    dataVehicle.iPrixReprise &&
    typeof dataVehicle.iPrixReprise === 'number'
  ) {
    const { iPrixReprise } = dataVehicle
    return {
      minContribution:
        iPrixReprise > minContribution ? minContribution : iPrixReprise,
      defaultContribute:
        iPrixReprise >= maxContribution ? maxContribution : iPrixReprise
    }
  }
  return {
    minContribution,
    defaultContribute: 5000
  }
}

export function getDescriptionDate(data: any) {
  if (
    data.hasOwnProperty('insC43FinancementDTO') ||
    !data.hasOwnProperty('aRetourFinancementErreur')
  ) {
    return {
      debut_date: moment(
        data.insC43FinancementDTO.d_fin_debut,
        DATE_FORMAT_FROM_API
      ).format(DATE_FORMAT),
      finish_date: moment(
        data.insC43FinancementDTO.d_fin_fin,
        DATE_FORMAT_FROM_API
      ).format(DATE_FORMAT)
    }
  }
  return {
    debut_date: '',
    finish_date: ''
  }
}

export function formatFundingOptions(obj: any) {
  return Object.entries(obj).map(([id, item]: any) => {
    return {
      label: item.insC43PrestationDTO.s_pre_libelle_commercial,
      moreInfo: item.insC43PrestationDTO.s_pre_texte_explicatif,
      value: id
    }
  })
}

export function getLabelOptionsById(dataFundingPlan: any, checkedOptions: any) {
  const listOptions = get(
    dataFundingPlan,
    'insC43FinancementDTO.aC43FinancementPrestationAssociationDTO',
    []
  )
  if (checkedOptions.length > 0) {
    return (
      checkedOptions.map(
        (item: any) =>
          listOptions[item].insC43PrestationDTO.s_pre_libelle_commercial
      ) || []
    )
  } else {
    return []
  }
}

export function getCommonValues(formValue: any) {
  return pick(formValue, COMMON_FIELD_NAME)
}

export function initialDataLOA(dataLOA: any, dataVehicle: any = {}) {
  if (dataLOA && isValidFundingData(dataLOA)) {
    const { aRetourFinancement, insC43FinancementDTO } = dataLOA
    const { aListeMoisPossibles: contributeMonth } = insC43FinancementDTO
    const maxKm = insC43FinancementDTO.i_fin_km_max || 40000
    const minKm = insC43FinancementDTO.i_fin_km_min || 10000
    const max =
      (aRetourFinancement.f_montant_facture *
        Number(insC43FinancementDTO.f_fin_taux_apport_max)) /
      100
    const maxContribution = Math.floor(max / 100) * 100
    const { minContribution, defaultContribute } = getContributionParams(
      dataLOA,
      dataVehicle,
      maxContribution
    )
    const minMonth = minObjectValue(contributeMonth)
    const maxMonth = maxObjectValue(contributeMonth)
    const listMonth = Object.values(contributeMonth)
    const defaultMonth = aRetourFinancement.i_duree_mois
    const {
      aC43FinancementPrestationAssociationDTO: listOptions
    } = insC43FinancementDTO
    const options = formatFundingOptions(listOptions)
    const day = getDescriptionDate(dataLOA)
    return {
      defaultContribute,
      defaultKm: Math.floor((maxKm + minKm) / 2000) * 1000,
      defaultMonth,
      listMonth,
      maxContribution,
      maxKm,
      maxMonth,
      minContribution,
      minKm,
      minMonth,
      options,
      day,
      invalidData: false
    }
  }

  return {
    defaultContribute: 0,
    defaultKm: 0,
    defaultMonth: 0,
    options: MOCK_OPTIONS,
    invalidData: true
  }
}
export function initialDataCRE(dataCRE: any, dataVehicle: any = {}) {
  if (dataCRE && isValidFundingData(dataCRE)) {
    const { aRetourFinancement, insC43FinancementDTO } = dataCRE
    const { aListeMoisPossibles: contributeMonth } = insC43FinancementDTO
    const maxKm = insC43FinancementDTO.i_fin_km_max || 40000
    const minKm = insC43FinancementDTO.i_fin_km_min || 10000
    let maxContribution =
      Math.floor(aRetourFinancement.f_montant_facture / 100) * 100
    if (aRetourFinancement.f_fin_taux_apport_max) {
      maxContribution =
        Math.floor(
          (aRetourFinancement.f_montant_total_du *
            aRetourFinancement.f_fin_taux_apport_max) /
            100 /
            100
        ) * 100
    }
    const { minContribution, defaultContribute } = getContributionParams(
      dataCRE,
      dataVehicle,
      maxContribution
    )

    const minMonth = minObjectValue(contributeMonth)
    const maxMonth = maxObjectValue(contributeMonth)
    const listMonth = Object.values(contributeMonth)
    const defaultMonth = aRetourFinancement.i_duree_mois
    const {
      aC43FinancementPrestationAssociationDTO: listOptions
    } = insC43FinancementDTO
    const options = formatFundingOptions(listOptions)
    const day = getDescriptionDate(dataCRE)
    return {
      defaultContribute,
      defaultKm: Math.floor((maxKm + minKm) / 2000) * 1000,
      defaultMonth,
      listMonth,
      maxContribution,
      maxKm,
      maxMonth,
      minContribution,
      minKm,
      minMonth,
      options,
      day,
      invalidData: false
    }
  }
  return {
    defaultContribute: 0,
    defaultKm: 0,
    defaultMonth: 0,
    options: MOCK_OPTIONS,
    invalidData: true
  }
}

export function mapListOption(listProfession: any) {
  return Object.entries(listProfession).map(([id, item]: any) => {
    return {
      label: item,
      value: id
    }
  })
}

export function formatFundingType(availableFunding: any) {
  const groupType = groupBy(availableFunding, 'aFinancement.i_fin_type')
  const validFunding = filter(availableFunding, item =>
    isValidFundingData(item)
  )
  const groupTypeValid = keyBy(validFunding, 'aFinancement.i_fin_type')
  return lodashAssign({}, groupType, pick(groupTypeValid, keys(groupType)))
}

export function isValidFundingData(data: any) {
  const sCgvHtml = get(data, 'aRetourFinancement.s_cgv_html', '')
  const sFinSousTitre = get(data, 'insC43FinancementDTO.s_fin_sous_titre', '')
  const isProduction = process.env.NODE_ENV === 'production'
  if (!sCgvHtml || (sFinSousTitre !== 'Test' && !isProduction)) {
    return false
  }
  if (
    data.hasOwnProperty('aRetourFinancementErreur') ||
    !data.hasOwnProperty('insC43FinancementDTO')
  ) {
    return false
  }
  return true
}

export function getPrixValue(vehicleConfigurator: any) {
  if (!isEmpty(vehicleConfigurator)) {
    return Math.round(
      get(vehicleConfigurator, 'f_off_prix_vente', 0) +
        get(vehicleConfigurator, 'f_off_total_remises', 0) -
        Number(get(vehicleConfigurator, 'f_off_carte_grise', 0))
    )
  }
  return ''
}

// Choosing between the data from the get-funding call at the first time and get-funding call from when user in finanical form

export function getDataFunding(context: Context) {
  const isStepInFinance = get(context, 'demand.isStepInFinance', false)
  const initialFinance = get(context, 'initialFinance', {})
  const initialDataFunding = get(initialFinance, 'dataFunding', {})
  const initialBackup = get(initialFinance, ['backup', context.plan], {})
  const demandFinance = get(context, 'demand.finance', {})
  // This is tricky here! The first time this function call, the under conditions is False and it would getting the initialFinance no matter what. From the second time, it would always taking the demandFinance because the backup inside ensure to be define
  const finance =
    !isEmpty(demandFinance) || isStepInFinance ? demandFinance : initialFinance
  const dataFunding = get(finance, 'dataFunding', initialDataFunding)
  const backup = get(finance, ['backup', context.plan], initialBackup)
  return [finance, dataFunding, backup]
}
