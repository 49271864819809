import { assign, EventObject, StateNodeConfig } from 'xstate'

import Routes from '../../constants/Routes'
import {
  getBrands,
  getVehicleFilterList,
  getVehicleMotorisation,
  getVehiclesFromBrand
} from '../../services/api'

export interface ManualSchema {
  states: {
    inServiceDate: {}
    fetchBrands: {}
    brand: {}
    fetchVehicles: {}
    vehicle: {}
    fetchEnergyAndModels: {}
    energyAndModel: {}
    fetchEngine: {}
    engineAndMileage: {}
  }
}

type ManualEventTypes = 'NEXT' | 'PREVIOUS' | 'FILLED' | 'ABORT'

export interface ManualEvent extends EventObject {
  type: ManualEventTypes
}

const GLOBAL_FAILURE = '#app.failure'
const FILL_FAILURE = '#app.fillFailure'
const ESTIMATE = '#app.demand.startWorkflow.estimate'

function resetVehicle() {
  return assign((ctx: any) => ({
    demand: {
      ...ctx.demand,
      manual: {
        ...ctx.demand.manual,
        vehicle: {
          ...ctx.demand.vehicle,
          s_veh_modname2: undefined
        }
      }
    }
  }))
}

function resetEnergyAndModel() {
  return assign((ctx: any) => ({
    demand: {
      ...ctx.demand,
      manual: {
        ...ctx.demand.manual,
        energy: undefined,
        model: undefined
      }
    }
  }))
}

function resetEngineAndMileage() {
  return assign((ctx: any) => ({
    demand: {
      ...ctx.demand,
      manual: {
        ...ctx.demand.manual,
        mileage: undefined,
        vehicle: {
          ...ctx.demand.vehicle,
          i_veh_maknatcode: ctx.demand.manual.vehicle.i_veh_maknatcode,
          s_veh_modname2: ctx.demand.manual.vehicle.s_veh_modname2
        }
      }
    }
  }))
}

/**
 * This is the State Machine part related to pages where user has to fill up info about his car
 */
// tslint:disable:object-literal-sort-keys
const node: StateNodeConfig<any, ManualSchema, ManualEvent> = {
  initial: 'inServiceDate',
  states: {
    inServiceDate: {
      meta: { path: Routes.InServiceDate },
      on: {
        PREVIOUS: ESTIMATE,
        NEXT: {
          target: 'fetchBrands',
          actions: assign((ctx, { month, year }) => ({
            demand: {
              ...ctx.demand,
              manual: {
                dDateMec: `${year}-${month || '01'}`
              }
            }
          }))
        }
      }
    },
    fetchBrands: {
      meta: { loading: true },
      invoke: {
        src: ctx => getBrands(ctx.demand.manual.dDateMec),
        onDone: {
          target: 'brand',
          actions: assign((_, { data }) => ({
            brands: data
          }))
        },
        onError: {
          target: GLOBAL_FAILURE,
          actions: 'consoleError'
        }
      }
    },
    brand: {
      meta: { path: Routes.Brand },
      on: {
        PREVIOUS: 'inServiceDate',
        NEXT: {
          target: 'fetchVehicles',
          actions: assign((ctx, { brand }) => ({
            demand: {
              ...ctx.demand,
              manual: {
                ...ctx.demand.manual,
                vehicle: {
                  i_veh_maknatcode: parseInt(brand, 10)
                },
                brand: parseInt(brand, 10)
              }
            }
          }))
        }
      }
    },
    fetchVehicles: {
      meta: { loading: true },
      invoke: {
        src: ctx =>
          getVehiclesFromBrand(
            ctx.demand.manual.brand,
            ctx.demand.manual.dDateMec
          ),
        onDone: {
          target: 'vehicle',
          actions: assign((_, { data }) => ({
            brandModels: data
          }))
        },
        onError: {
          target: GLOBAL_FAILURE,
          actions: 'consoleError'
        }
      }
    },
    vehicle: {
      meta: { path: Routes.Vehicle },
      on: {
        PREVIOUS: [
          {
            target: 'brand',
            actions: resetVehicle(),
            cond: ctx => ctx.brands !== undefined
          },
          {
            target: 'fetchBrands',
            actions: resetVehicle()
          }
        ],
        NEXT: {
          target: 'fetchEnergyAndModels',
          actions: assign((ctx, { vehicle }) => ({
            demand: {
              ...ctx.demand,
              manual: {
                ...ctx.demand.manual,
                vehicle: {
                  ...ctx.demand.manual.vehicle,
                  s_veh_modname2: vehicle
                }
              }
            }
          }))
        }
      }
    },
    fetchEnergyAndModels: {
      meta: { loading: true },
      invoke: {
        src: ctx =>
          Promise.all([
            getVehicleFilterList(
              ctx.demand.manual.vehicle.s_veh_modname2,
              ctx.demand.manual.brand,
              's_veh_typtxtfueltypecd2',
              ctx.demand.manual.dDateMec
            ),
            getVehicleFilterList(
              ctx.demand.manual.vehicle.s_veh_modname2,
              ctx.demand.manual.brand,
              's_veh_typname2',
              ctx.demand.manual.dDateMec
            )
          ]),
        onDone: {
          target: 'energyAndModel',
          actions: assign((_, { data: [fuelModels, models] }) => ({
            fuelModels,
            models
          }))
        },
        onError: {
          target: GLOBAL_FAILURE,
          actions: 'consoleError'
        }
      }
    },
    energyAndModel: {
      meta: { path: Routes.EnergyAndModel },
      on: {
        PREVIOUS: [
          {
            target: 'vehicle',
            actions: resetEnergyAndModel(),
            cond: ctx => ctx.brandModels !== undefined
          },
          {
            target: 'fetchVehicles',
            actions: resetEnergyAndModel()
          }
        ],
        NEXT: {
          target: 'fetchEngine',
          actions: assign((ctx, { energy, model }) => ({
            demand: {
              ...ctx.demand,
              manual: {
                ...ctx.demand.manual,
                energy,
                model
              }
            }
          }))
        }
      }
    },
    fetchEngine: {
      meta: { loading: true },
      invoke: {
        src: ctx =>
          getVehicleMotorisation(
            ctx.demand.manual.vehicle.s_veh_modname2,
            ctx.demand.manual.brand,
            ctx.demand.manual.energy,
            ctx.demand.manual.model,
            ctx.demand.manual.dDateMec
          ),
        onDone: [
          {
            target: 'engineAndMileage',
            cond: (_, { data }) => data.length > 0,
            actions: assign((_, { data: engineModels }) => ({
              engineModels
            }))
          },
          {
            target: FILL_FAILURE,
            cond: (_, { data }) => data.length === 0
          }
        ],
        onError: {
          target: GLOBAL_FAILURE,
          actions: 'consoleError'
        }
      }
    },
    engineAndMileage: {
      meta: { path: Routes.EngineAndMileage },
      on: {
        PREVIOUS: [
          {
            target: 'energyAndModel',
            actions: resetEngineAndMileage(),
            cond: ctx =>
              ctx.fuelModels !== undefined && ctx.models !== undefined
          },
          {
            target: 'fetchEnergyAndModels',
            actions: resetEngineAndMileage()
          }
        ],
        NEXT: {
          target: '#app.demand.state',
          actions: [
            assign((ctx, { vehicle, mileage }) => ({
              demand: {
                ...ctx.demand,

                manual: {
                  ...ctx.demand.manual,
                  mileage,
                  vehicle: {
                    ...ctx.demand.manual.vehicle,
                    ...JSON.parse(vehicle)
                  }
                }
              }
            }))
          ]
        }
      }
    }
  },
  on: {
    FILLED: {
      actions: assign((ctx, { isValid, values }) => ({
        demand: { ...ctx.demand, form: { isValid, values } }
      }))
    }
  }
}

export default node
