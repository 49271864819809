import isEmpty from 'lodash/isEmpty'
import { assign, DoneInvokeEvent, EventObject, Machine, send } from 'xstate'

import get from 'lodash/get'
import Routes from '../../constants/Routes'
import {
  getAvailableFunding,
  getDynamicOffer,
  getFundingFirstTime,
  getInitialData,
  setTryOutDemand
} from '../../services/api'
import {
  FUNDING_CODE,
  FUNDING_MATCH,
  FUNDING_PLAN
} from '../../utils/constants'
import {
  getFundingPlan,
  getStep,
  KeyValue,
  LabelValue,
  resetFundingPlan
} from '../../utils/dataFormat'
import { convertParamsToObject } from '../../utils/url'
import City from '../../vo/City'
import Config from '../../vo/Config'
import Dealer from '../../vo/Dealer'
import { VehicleOptions } from '../../vo/Demand'
import DynamicOffer from '../../vo/DynamicOffer'
import Estimation from '../../vo/Estimation'
import Location from '../../vo/Location'
import Offer from '../../vo/Offer'
import Options from '../../vo/Options'
import { Project } from '../../vo/Project'
import Vehicle from '../../vo/Vehicle'
import VehicleByLicense from '../../vo/VehicleByLicense'
import VehicleElements, { DamageElement } from '../../vo/VehicleElements'
import { retrieve } from '../sync'
import confirmation, {
  ConfirmationEvent,
  ConfirmationSchema
} from './confirmation'
import contact, { ContactEvent, ContactSchema } from './contact'
import finance, { FinanceEvent, FinanceSchema } from './finance'
import manual, { ManualEvent, ManualSchema } from './manual'
import startWorkflow, {
  StartWorkflowEvent,
  StartWorkflowSchema
} from './startWorkflow'
import state, { StateEvent, StateSchema } from './state'

export const SKODA_BRAND_ID = 28
const GLOBAL_HISTORIC = 'demand.hist'
const STARTWORKFLOW = '#app.demand.startWorkflow'
const DEALER = '#app.demand.startWorkflow.dealer'
const ESTIMATE = '#app.demand.startWorkflow.estimate'
const FINANCE = '#app.demand.finance'
const CONFIRMATION = '#app.demand.confirmation'
const ENERGY_AND_MODEL = '#app.demand.manual.energyAndModel'
export interface UserInformationsContext {
  sCiviliteClient?: string
  sEmailClient?: string
  sNomClient?: string
  sPrenomClient?: string
  phone_number?: string
  requested_for?: string
  zip_code?: string
  city?: City
  email?: string
  i_con_id?: number
}
export interface Schema {
  states: {
    idle: {}
    pending: {
      states: {
        impactFetch: {}
        unImpactFetch: {}
        initialFundingFetch: {}
        errorUnImpactFetch: {}
      }
    }
    resumedSession: {}
    demand: {
      states: {
        hist: {}
        startWorkflow: StartWorkflowSchema
        userInformations: {}
        finance: FinanceSchema
        manual: ManualSchema
        state: StateSchema
        confirmation: ConfirmationSchema
      }
    }
    contact: ContactSchema
    contactform: {}
    failure: {}
    failureId: {}
    failureInit: {}
    failureFunding: {}
    vehicleImportedFailure: {}
    fillFailure: {}
    fetchQuote: {}
    vehicleNotEstimated: {}
    navigators: {}
    abortModal: {}
    errorFetchDemand: {}
    errorDataEstimate: {}
  }
}

export interface ManualContext {
  dDateMec: string
  brand: string
  energy: string
  model: string
  engine: string
  mileage: string
  sCouleurInt: string
  sCouleurExt: string
  bCarnetEntretient: boolean
  bImport: boolean
  bPremiereMain: boolean
  exteriorCondition: DamageElement[]
  tyres: KeyValue<string>
  options: Options[]
  vehicle?: Vehicle
}

export interface FinanceContext {
  plan?: string
  dataFunding: any
  dataFundingError: string
  fundingDeal?: any
  backup?: any
  vehicleConfiguratorOffer?: any
}

export interface DemandContext {
  isDealer?: boolean
  isTradeIn?: boolean
  isFinance?: boolean
  userCategory?: string
  confirmationType?: string
  campaign?: string
  form?: {
    isValid: boolean
    values: any
  }
  userInformations?: UserInformationsContext
  vehicleByLicense?: VehicleByLicense[]
  mediaByLicense?: any[]
  vehicle?: Vehicle
  manual?: ManualContext
  estimation?: Estimation
  selectedOffer?: Offer
  selectedDealer?: Dealer
  dealer?: Dealer
  dealers?: any
  project?: string
  isAnonymousDemand?: boolean
  skodaVehicle?: string
  vehicleId?: any
  kilometer?: number
  dataVehicle?: Estimation
  vehSelect?: VehicleByLicense
  lastStep?: boolean
  finance?: FinanceContext
  dataFunding?: any
  fundingDeal?: {
    isValid: boolean
    values: any
  }
  currentUserLocation?: Location
  dynamicOffer?: any
  backup?: {
    isValid: boolean
    values: any
  }
  vehicleConfiguratorOffer?: any
  payByCash?: boolean
  configurationId?: string
  isStepInFinance?: boolean
  iIdReprise?: number
  isUndefinedEstimate?: boolean
}

export interface SkodaVehicles {
  individual: LabelValue[]
  professionnal: LabelValue[]
}
export enum StepsEvent {
  CONFIGURATION = 0,
  DEALER = 1,
  TRADEIN = 2,
  FUNDING = 3,
  APPOINTMENT = 4
}
export interface Context {
  config?: Config
  vehicleConfigurator?: any
  skodaVehicles?: SkodaVehicles
  brands?: LabelValue[]
  models?: LabelValue[]
  brandModels?: LabelValue[]
  fuelModels?: LabelValue[]
  engineModels?: Vehicle[]
  vehicleElements?: VehicleElements[]
  vehicleOptions?: VehicleOptions[]
  offers?: any
  demand: DemandContext
  availableFunding?: any
  plan?: any
  queryParams?: any
  step?: StepsEvent
  jump?: StepsEvent
  dynamicOffer?: DynamicOffer
  contactType?: string
  initialFinance?: FinanceContext
}

interface DemandEvent extends EventObject {
  type:  // tslint:disable-next-line:max-union-size
    | 'FETCH'
    | 'RETRY'
    | 'SET_DEALER'
    | 'APPOINTMENT'
    | 'PHONE'
    | 'EMAIL'
    | 'FETCH_QUOTE'
    | 'REDIRECT'
    | 'START_NEW_SESSION'
    | 'VEHICLE_IMPORTED_FAILURE'
    | 'APPOINTMENT_WITHOUT_ESTIMATION'
    | 'PHONE_WITHOUT_ESTIMATION'
    | 'JUMP'
    | 'ABORT'
    | 'OPEN_ABORT'
}

export enum UserCategory {
  PROFESSIONNEL = 'Professionnel',
  PARTICULIER = 'Particulier'
}

export type Event =
  | DemandEvent
  | StartWorkflowEvent
  | ManualEvent
  | StateEvent
  | ContactEvent
  | FinanceEvent
  | ConfirmationEvent

/**
 * This is the global application State Machine
 */
// tslint:disable:object-literal-sort-keys
export default Machine<Context, Schema, Event>(
  {
    id: 'app',
    initial: 'idle',
    context: {
      // app configuration, loaded while "pending" state
      config: undefined,
      // Skoda vehicles list
      skodaVehicles: undefined,
      // brands list
      brands: undefined,
      // vehicle models list
      models: undefined,
      // brand models list
      brandModels: undefined,
      // fuel models list
      fuelModels: undefined,
      // engine models list
      engineModels: undefined,
      // vehicle parts list
      vehicleElements: undefined,
      // vehicle options list
      vehicleOptions: undefined,
      // skoda offer list
      offers: undefined,
      // try to retrieve demand from localStorage or sessionStorage or demand creation
      demand: retrieve('demand') || {
        userCategory: UserCategory,
        form: undefined,
        vehicle: undefined,
        manual: undefined,
        estimation: undefined,
        selectedOffer: undefined,
        userInformations: undefined,
        confirmationType: undefined,
        campaign: undefined,
        isAnonymousDemand: true,
        skodaVehicle: null
      },
      queryParams: convertParamsToObject() ||
        retrieve('qp') || {
          configurationId: undefined
        },
      step: StepsEvent.CONFIGURATION
    },
    // @ts-ignore
    states: {
      idle: {
        on: {
          FETCH: 'pending'
        }
      },
      pending: {
        meta: { loading: true },
        initial: 'impactFetch',
        states: {
          impactFetch: {
            invoke: {
              src: (ctx: Context) => getInitialData(ctx.queryParams),
              onDone: { target: 'unImpactFetch', actions: ['setInitialData'] },
              onError: [
                {
                  target: '#app.failureInit',
                  cond: (ctx: Context) => !!ctx.queryParams.configurationId,
                  actions: 'consoleError'
                },
                {
                  target: '#app.failureId',
                  cond: (ctx: Context) => !ctx.queryParams.configurationId,
                  actions: 'consoleError'
                }
              ]
            }
          },
          unImpactFetch: {
            invoke: [
              {
                src: (_: Context) => getDynamicOffer(),
                onDone: {
                  actions: assign((ctx: Context, { data }) => ({
                    ...ctx,
                    dynamicOffer: data
                  }))
                },
                onError: {
                  actions: [
                    assign((ctx: Context) => ({
                      ...ctx,
                      dynamicOffer: undefined
                    })),
                    'consoleError'
                  ]
                }
              },
              {
                src: (ctx: Context) =>
                  getAvailableFunding(
                    ctx.vehicleConfigurator,
                    ctx.config!.displayRefunding
                  ),
                onDone: {
                  target: 'initialFundingFetch',
                  actions: ['setAvailableFundingData']
                },
                onError: {
                  target: 'errorUnImpactFetch',
                  actions: ['setErrorFundingData', 'consoleError']
                }
              }
            ]
          },
          initialFundingFetch: {
            invoke: {
              src: (ctx: Context) =>
                getFundingFirstTime(
                  ctx.availableFunding,
                  ctx.vehicleConfigurator,
                  ctx.plan
                ),
              onDone: [
                {
                  target: STARTWORKFLOW,
                  cond: 'isBegin',
                  actions: ['setFundingFirstCall', 'resetDemandSession']
                },
                {
                  target: '#app.resumedSession',
                  cond: 'isResummedSession',
                  actions: ['setFundingFirstCall', 'setInitialStep']
                }
              ],
              onError: {
                target: 'errorUnImpactFetch',
                actions: 'consoleError'
              }
            }
          },
          errorUnImpactFetch: {
            entry: send('REDIRECT'),
            on: {
              REDIRECT: [
                {
                  target: STARTWORKFLOW,
                  cond: 'isBegin',
                  actions: ['resetDemandSession']
                },
                {
                  target: '#app.resumedSession',
                  cond: 'isResummedSession',
                  actions: ['setInitialStep']
                }
              ]
            }
          }
        }
      },
      resumedSession: {
        on: {
          REDIRECT: [
            {
              target: 'demand.startWorkflow.estimate.idle',
              cond: 'introTradein'
            },
            {
              target: 'demand.manual.inServiceDate',
              cond: 'searchDDateMec'
            },
            {
              target: 'demand.manual.fetchBrands',
              cond: 'searchBrand'
            },
            {
              target: 'demand.manual.fetchVehicles',
              cond: 'searchVehicle'
            },
            {
              target: 'demand.manual.fetchEnergyAndModels',
              cond: 'searchEnergyAndFuel'
            },
            {
              target: 'demand.manual.fetchEngine',
              cond: 'searchEngine'
            },
            {
              target: 'demand.startWorkflow.found',
              cond: 'searchVehicleFound'
            },
            {
              target: 'demand.userInformations',
              cond: 'searchUserInformations'
            },
            {
              target: 'vehicleNotEstimated',
              cond: 'vehicleNotEstimated'
            },
            {
              target: 'demand.state.color',
              cond: 'searchColor'
            },
            {
              target: 'demand.state.details',
              cond: 'searchVehicleInformations'
            },
            {
              target: 'demand.state.fetchElements',
              cond: 'searchExteriorConditions'
            },
            {
              target: 'demand.state.tyres',
              cond: 'searchTyres'
            },
            {
              target: 'demand.state.fetchOptions',
              cond: 'searchOptions'
            },
            {
              target: 'demand.state.fetchEstimateOffers',
              cond: 'searchSelectedOffer'
            },
            {
              target: 'demand.startWorkflow.pickYourVehicle',
              cond: 'multiVehicleEstimate'
            },
            {
              target: 'demand.startWorkflow.yourVehicle',
              cond: 'myVehicleEstimate'
            },
            {
              target: 'demand.startWorkflow.estimateResult.idle',
              cond: 'estimateResult'
            },
            {
              target: 'demand.startWorkflow.estimateResultManual',
              cond: 'estimateResultManual'
            },
            {
              target: 'demand.finance',
              cond: 'refunding'
            },
            {
              target: 'demand.confirmation',
              cond: 'confirmation'
            }
          ],
          START_NEW_SESSION: {
            target: STARTWORKFLOW,
            actions: ['resetDemandSession', 'resetFundingPlan']
          }
        }
      },
      demand: {
        initial: 'startWorkflow',
        states: {
          hist: {
            type: 'history',
            history: 'deep',
            target: 'idle'
          },
          startWorkflow,
          userInformations: {
            meta: { path: Routes.Contact },
            on: {
              FILLED: {
                actions: assign((ctx: Context, { isValid, values }) => ({
                  demand: { ...ctx.demand, form: { isValid, values } }
                }))
              },
              NEXT: [
                {
                  target: '#app.demand.state',
                  cond: ({ demand }: Context) =>
                    demand.manual && !demand.manual.vehicle,
                  actions: 'setUserInformations'
                },
                {
                  target: '#app.demand.manual',
                  actions: 'setUserInformations'
                }
              ],
              PREVIOUS: [
                {
                  target: '#app.demand.startWorkflow.found',
                  cond: ({ demand }: Context) =>
                    demand.vehicle && demand.vehicle.s_veh_typnatcode
                },
                { target: STARTWORKFLOW }
              ]
            }
          },
          finance,
          manual,
          state,
          confirmation,
          contactform: {
            meta: { path: Routes.ContactForm },
            on: {
              REDIRECT: [
                {
                  target: '#app.demand.confirmation',
                  cond: (ctx: Context) => !!ctx.demand.lastStep,
                  actions: assign((ctx: Context) => ({
                    ...ctx,
                    contactType: undefined
                  }))
                },
                {
                  target: '#app.demand.startWorkflow.dealer',
                  cond: (ctx: Context) => !ctx.demand.lastStep
                }
              ]
            }
          }
        }
      },
      contact,
      failure: {
        on: {
          RETRY: GLOBAL_HISTORIC
        }
      },
      failureId: {},
      failureInit: {
        on: {
          RETRY: '#app.pending'
        }
      },
      failureFunding: {
        entry: send('REDIRECT'),
        on: {
          REDIRECT: STARTWORKFLOW
        }
      },
      vehicleImportedFailure: {
        on: {
          RETRY: GLOBAL_HISTORIC
        }
      },
      fillFailure: {
        exit: {
          actions: assign(({ demand }) => ({
            ...demand,
            demand: {
              manual: {
                model: undefined,
                energy: undefined,
                ...demand.manual
              }
            }
          }))
        },
        on: {
          RETRY: ENERGY_AND_MODEL
        }
      },
      fetchQuote: {
        meta: { loading: true },
        invoke: {
          src: (ctx: Context) => setTryOutDemand(ctx.demand),
          onDone: [
            {
              target: GLOBAL_HISTORIC,
              cond: (_: Context, { data }: any) => !!data.iPrixReprise,
              actions: assign((ctx: Context, { data }) => ({
                demand: {
                  ...ctx.demand,
                  dataVehicle: data,
                  estimation: {
                    ...data,
                    iPrixReprise:
                      ctx.demand.userCategory === UserCategory.PROFESSIONNEL
                        ? Math.round(data.iPrixReprise / ctx.config!.TVA)
                        : data.iPrixReprise
                  },
                  userInformations: {
                    ...ctx.demand.userInformations,
                    i_con_id: data && data.aReprise && data.aReprise.i_con_id
                  }
                }
              }))
            },
            {
              target: 'vehicleNotEstimated',
              actions: assign((ctx: Context, { data }) => ({
                demand: {
                  ...ctx.demand,
                  estimation: {
                    ...data
                  }
                }
              }))
            }
          ],
          onError: {
            target: 'failure',
            actions: 'consoleError'
          }
        }
      },
      vehicleNotEstimated: {
        meta: {
          path: Routes.CarNotEstimated
        },
        on: {
          PREVIOUS: {
            target: STARTWORKFLOW,
            actions: assign(() => ({
              demand: {
                vehicle: undefined,
                manual: undefined
              }
            }))
          }
        }
      },
      navigators: {
        entry: send('REDIRECT'),
        on: {
          REDIRECT: [
            {
              target: STARTWORKFLOW,
              cond: (ctx: Context) => ctx.jump === StepsEvent.CONFIGURATION
            },
            {
              target: DEALER,
              cond: (ctx: Context) => ctx.jump === StepsEvent.DEALER
            },
            {
              target: ESTIMATE,
              cond: (ctx: Context) =>
                ctx.jump === StepsEvent.TRADEIN && !ctx.demand.isTradeIn
            },
            {
              target: '#app.demand.startWorkflow.estimateResult',
              cond: (ctx: Context) =>
                ctx.jump === StepsEvent.TRADEIN &&
                !!ctx.demand.isTradeIn &&
                isEmpty(ctx.demand.manual || {})
            },
            {
              target: '#app.demand.startWorkflow.estimateResultManual',
              cond: (ctx: Context) =>
                ctx.jump === StepsEvent.TRADEIN &&
                !!ctx.demand.isTradeIn &&
                !isEmpty(ctx.demand.manual || {})
            },
            {
              target: FINANCE,
              cond: (ctx: Context) => ctx.jump === StepsEvent.FUNDING
            },
            {
              target: CONFIRMATION,
              cond: (ctx: Context) => ctx.jump === StepsEvent.APPOINTMENT
            },
            {
              target: GLOBAL_HISTORIC,
              cond: (ctx: Context) => !ctx.jump || ctx.jump === ctx.step
            }
          ]
        }
      },
      abortModal: {
        on: {
          DONE: {
            target: GLOBAL_HISTORIC
          }
        }
      },
      errorFetchDemand: {
        on: {
          RESOLVE: [
            {
              target: '#app.demand.startWorkflow.yourVehicle',
              cond: ctx =>
                !!ctx.demand.vehicleByLicense &&
                ctx.demand.vehicleByLicense.length === 1 &&
                ctx.step === StepsEvent.TRADEIN
            },
            {
              target: '#app.demand.startWorkflow.pickYourVehicle',
              cond: ctx =>
                !!ctx.demand.vehicleByLicense &&
                ctx.demand.vehicleByLicense.length > 1 &&
                ctx.step === StepsEvent.TRADEIN
            },
            {
              target: '#app.demand.state.options',
              cond: ctx =>
                !!ctx.demand.manual &&
                !isEmpty(ctx.demand.manual) &&
                ctx.step === StepsEvent.TRADEIN
            },
            {
              target: '#app.demand.startWorkflow.dealer.found',
              cond: ctx => ctx.step === StepsEvent.DEALER
            }
          ]
        }
      },
      errorDataEstimate: {
        on: {
          RESOLVE: FINANCE
        }
      }
    },
    on: {
      VEHICLE_IMPORTED_FAILURE: 'vehicleImportedFailure',
      SET_DEALER: {
        target: GLOBAL_HISTORIC,
        actions: assign((ctx: Context, { selectedDealer }) => ({
          demand: {
            ...ctx.demand,
            selectedDealer
          }
        }))
      },
      FETCH_QUOTE: 'fetchQuote',
      APPOINTMENT_WITHOUT_ESTIMATION: 'contact.appointment',
      PHONE_WITHOUT_ESTIMATION: 'contact.phone',
      JUMP: {
        target: 'navigators',
        actions: assign((ctx: Context, { selectedStep }) => ({
          ...ctx,
          jump: selectedStep
        }))
      },
      OPEN_ABORT: {
        target: '#app.abortModal'
      },
      ABORT: {
        target: FINANCE,
        actions: ['resetEstimateContext']
      }
    }
  },
  {
    guards: {
      introTradein: (ctx: Context) =>
        ctx.demand.isDealer &&
        !ctx.demand.vehicleId &&
        !ctx.demand.lastStep &&
        isEmpty(ctx.demand.manual || {}) &&
        isEmpty(ctx.demand.finance || {}),
      searchDDateMec: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        !ctx.demand.manual!.dDateMec,
      searchBrand: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        !ctx.demand.manual!.brand,
      searchVehicle: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        !ctx.demand.manual!.vehicle!.s_veh_modname2,
      searchEnergyAndFuel: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        !ctx.demand.vehicle &&
        !ctx.demand.manual!.model &&
        !ctx.demand.manual!.energy,
      searchEngine: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        !ctx.demand.manual!.mileage,
      searchVehicleFound: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        ctx.demand.vehicle &&
        !ctx.demand.manual!.sCouleurInt,
      searchUserInformations: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.userInformations!.sEmailClient &&
        ctx.demand.project === Project.KNOW_PRICE,
      searchColor: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.vehicleId &&
        !ctx.demand.manual!.sCouleurExt &&
        !ctx.demand.manual!.sCouleurInt,
      searchVehicleInformations: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        ctx.demand.manual!.sCouleurExt &&
        ctx.demand.manual!.sCouleurInt &&
        ctx.demand.manual!.bCarnetEntretient === undefined,
      searchExteriorConditions: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        ctx.demand.manual!.bCarnetEntretient !== undefined &&
        ctx.demand.manual!.bImport !== undefined &&
        ctx.demand.manual!.bPremiereMain !== undefined &&
        !ctx.demand.manual!.exteriorCondition,
      searchTyres: (ctx: Context) =>
        !isEmpty(ctx.demand.manual || {}) &&
        !ctx.demand.manual!.tyres &&
        ctx.demand.manual!.exteriorCondition &&
        ctx.demand.manual!.exteriorCondition.length > 0,
      searchOptions: (ctx: Context) =>
        isEmpty(ctx.demand.dataVehicle || {}) &&
        !isEmpty(ctx.demand.manual || {}) &&
        ctx.demand.manual!.tyres,
      searchSelectedOffer: (ctx: Context) =>
        isEmpty(ctx.demand.dataVehicle || {}) && ctx.demand.manual!.options,
      vehicleNotEstimated: (ctx: Context) =>
        ctx.demand.estimation && !ctx.demand.estimation.iPrixReprise,
      myVehicleEstimate: (ctx: Context) =>
        isEmpty(ctx.demand.dataVehicle || {}) &&
        !isEmpty(ctx.demand.vehSelect || {}) &&
        ctx.demand.vehicleId !== -1 &&
        ctx.demand.vehicleByLicense!.length === 1,
      multiVehicleEstimate: (ctx: Context) =>
        isEmpty(ctx.demand.dataVehicle || {}) &&
        ctx.demand.vehicleByLicense!.length > 1,
      estimateResult: (ctx: Context) =>
        !isEmpty(ctx.demand.dataVehicle || {}) &&
        (isEmpty(ctx.demand.manual || {}) || !ctx.demand.manual!.sCouleurExt) &&
        !ctx.demand.isTradeIn,
      estimateResultManual: (ctx: Context) =>
        !isEmpty(ctx.demand.dataVehicle || {}) &&
        !isEmpty(ctx.demand.manual || {}) &&
        ctx.demand.manual!.options &&
        !ctx.demand.isTradeIn,
      refunding: (ctx: Context) =>
        !isEmpty(ctx.demand.finance || {}) && !ctx.demand.lastStep,
      confirmation: (ctx: Context) => !!ctx.demand.lastStep,
      isBegin: (ctx: Context) =>
        (!ctx.demand.vehicleId && !ctx.demand.manual && !ctx.demand.finance) ||
        ctx.demand.configurationId !== ctx.queryParams.configurationId,
      isResummedSession: (ctx: Context) =>
        (ctx.demand.manual || ctx.demand.vehicleId || ctx.demand.finance) &&
        ctx.demand.configurationId === ctx.queryParams.configurationId
    },
    actions: {
      setSelectedOffer: assign((ctx: Context) => ({
        demand: {
          ...ctx.demand,
          selectedOffer: ctx.offers.filter(
            (offer: any) => offer.id === Number(ctx.demand.form!.values.offers)
          )[0]
        }
      })),
      setInitialData: assign((ctx: Context, { data: initialData }) => ({
        ...ctx,
        config: initialData.appConfiguration,
        vehicleConfigurator: initialData.vehicleConfigurator
      })),
      setInitialStep: assign((ctx: Context) => ({
        ...ctx,
        step: getStep()
      })),
      setAvailableFundingData: assign((ctx: Context, { data }) => ({
        ...ctx,
        availableFunding: data,
        plan: getFundingPlan(data)
      })),
      setFundingFirstCall: assign((ctx: Context, { data }) => ({
        ...ctx,
        // reassign the availableFunding when first funding call is made to update the new value max-apport field, only on type LEASING
        availableFunding: {
          ...ctx.availableFunding,
          ...(ctx.plan === FUNDING_PLAN.LEASING && {
            [get(FUNDING_MATCH, ctx.plan, '')]: data.fundingData
          })
        },
        initialFinance: {
          dataFundingError: '',
          dataFunding: {
            [ctx.plan]: data.fundingData
          },
          backup: {
            [ctx.plan]: {
              values: data.initialBackupValue
            }
          },
          keyForm: Math.floor(Math.random() * 1000)
        }
      })),
      setErrorFundingData: assign((ctx: Context) => ({
        ...ctx,
        availableFunding: {
          [FUNDING_CODE.LEASING]: {}
        },
        plan: resetFundingPlan(),
        demand: {
          ...ctx.demand,
          finance: undefined
        }
      })),
      setUserInformations: assign(
        (
          ctx: Context,
          {
            sCiviliteClient,
            sNomClient,
            sPrenomClient,
            sEmailClient,
            phone_number
          }
        ) => ({
          demand: {
            ...ctx.demand,
            userInformations: {
              ...ctx.demand.userInformations,
              sCiviliteClient,
              sNomClient,
              sPrenomClient,
              sEmailClient,
              phone_number
            }
          }
        })
      ),
      resetDemandSession: assign((ctx: Context) => ({
        ...ctx,
        step: 0,
        demand: {
          isAnonymousDemand: true,
          isDealer: false,
          isTradeIn: false,
          isFinance: false,
          userInformations: undefined,
          configurationId: ctx.queryParams.configurationId
        }
      })),
      resetUserInfo: assign((ctx: Context) => ({
        demand: {
          ...ctx.demand,
          userInformations: undefined
        }
      })),
      resetEstimateContext: assign(({ demand }) => ({
        demand: {
          ...demand,
          vehicleId: undefined,
          kilometer: undefined,
          dataVehicle: undefined,
          vehSelect: undefined,
          vehicleByLicense: [],
          mediaByLicense: [],
          manual: {},
          isTradeIn: false,
          isUndefinedEstimate: undefined
        }
      })),
      resetFundingPlan: assign((ctx: Context) => ({
        ...ctx,
        plan: resetFundingPlan(ctx.availableFunding)
      })),
      resetFunding: assign(({ demand }) => ({
        demand: {
          ...demand,
          backup: undefined,
          finance: {},
          lastStep: undefined,
          payByCash: undefined,
          isFinance: false
        }
      })),
      resetFundingContext: assign(({ demand }) => ({
        demand: {
          ...demand,
          payByCash: undefined
        }
      })),
      setStepTradeIn: assign((ctx: Context) => ({
        ...ctx,
        step: StepsEvent.TRADEIN,
        demand: {
          ...ctx.demand,
          step: StepsEvent.TRADEIN
        }
      })),
      resetIsTradeIn: assign(({ demand }: Context) => ({
        demand: {
          ...demand,
          isTradeIn: false
        }
      })),
      setStepDealer: assign((ctx: Context) => ({
        ...ctx,
        step: StepsEvent.DEALER,
        demand: {
          ...ctx.demand,
          step: StepsEvent.DEALER
        }
      })),
      setStepFinance: assign((ctx: Context) => ({
        ...ctx,
        step: StepsEvent.FUNDING,
        demand: {
          ...ctx.demand,
          step: StepsEvent.FUNDING,
          isStepInFinance: true
        }
      })),
      setBackupFinance: assign((ctx: Context) => ({
        ...ctx,
        demand: {
          ...ctx.demand,
          ...(isEmpty(ctx.demand.finance || {}) && {
            finance: ctx.initialFinance
          })
        }
      })),
      setStepConfirmation: assign((ctx: Context) => ({
        ...ctx,
        step: StepsEvent.APPOINTMENT,
        demand: {
          ...ctx.demand,
          lastStep: true
        }
      })),
      consoleError: (_: Context, errors: DoneInvokeEvent<any>) =>
        // tslint:disable-next-line:no-console
        console.error(errors)
    }
  }
)
