import * as Yup from 'yup'

import required from './required'

export default function kilometer(message: string, requiredMessage?: string) {
  const numberRegExp = /^([0-9\s]*km|[0-9\s]*)$/
  if (requiredMessage) {
    return required(requiredMessage).matches(numberRegExp, { message })
  }

  return Yup.string().matches(numberRegExp, { message })
}
