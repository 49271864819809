import cs from 'classnames'
import React, { InputHTMLAttributes } from 'react'
import styles from './RangeInput.module.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  hasError?: boolean
  min?: number | string
  max?: number | string
  value?: number | string
  labelHidden?: boolean
  labelClassName?: string
  listMonth?: number[]
  listValue?: any
}

export default function RangeInput({
  id,
  name,
  disabled,
  min = 0,
  max = 100,
  value,
  onChange,
  onFocus,
  onBlur,
  defaultValue,
  className,
  label,
  listValue,
  labelHidden = false,
  labelClassName
}: Props) {
  const valueNow =
    Number(value) === 0
      ? 0
      : value || defaultValue || (Number(max) + Number(min)) / 2
  const fieldStyles = cs(className, styles.range__input)
  const labelStyles = cs(styles.range__input__label, labelClassName, {
    [styles.disabled]: disabled,
    [styles.label__hidden]: labelHidden
  })
  const isListValue = listValue && listValue.length > 1
  const valueMin = isListValue ? 0 : min
  const valueMax = isListValue ? listValue.length - 1 : max
  const currentValue = isListValue ? listValue.indexOf(valueNow) : valueNow
  const variableRangeInput: any = {
    '--max': `${valueMax}`,
    '--min': `${valueMin}`,
    '--val': `${currentValue}`
  }
  const inputStyles = cs(styles.js, styles.input__range)
  return (
    <div className={fieldStyles}>
      <label htmlFor={id} className={labelStyles}>
        {label}
      </label>
      <input
        className={inputStyles}
        type="range"
        id={id}
        name={name}
        min={valueMin}
        max={valueMax}
        value={currentValue}
        role="slider"
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        aria-valuemax={Number(valueMax)}
        aria-valuemin={Number(valueMin)}
        aria-valuenow={Number(currentValue)}
        style={variableRangeInput}
      />
    </div>
  )
}
