import cs from 'classnames'
import debounce from 'debounce-promise'
import React, { useMemo, useState } from 'react'
import AsyncSelect from 'react-select/async'

import './Geolocation.scss'

import Icon, { IconNames } from '../../icons'

interface Props<T> {
  label: string
  placeholder: string
  selectType?: 'geolocation' | 'field'
  hasError?: boolean
  noOptionsMessage(): string
  loadingMessage(): string
  onChange(position: T, inputValue: string): void
  onBlur?(): void
  getOptions(searchText: string): Promise<T[]>
  getPosition?(): Promise<T[]>
}

export default function GeolocationSelect<T>({
  getOptions,
  getPosition,
  label,
  placeholder,
  onChange,
  onBlur,
  selectType = 'geolocation',
  hasError = false,
  noOptionsMessage,
  loadingMessage
}: Props<T>) {
  const [options, setOptions] = useState()
  const [value, setValue] = useState()
  const [inputValue, setInputValue] = useState('')

  const debouncedGetOptions = useMemo(() => debounce(getOptions, 500), [
    getOptions
  ])

  function mapName(option: any): string {
    return option.name.toUpperCase()
  }

  function mapZipCode(option: any): string {
    return option.zip_codes.substring(0, 5)
  }

  function loadOptions(searchText: string) {
    if (searchText.length < 2) {
      return
    }
    return debouncedGetOptions(searchText)
  }

  function handleChange(position: T) {
    setValue(position)
    onChange(position, inputValue)
  }

  function handleFocus() {
    setValue('')
  }

  function onInputChange(text: string) {
    setInputValue(text.toUpperCase())
  }

  async function handleClick() {
    const [position]: T[] = await getPosition!().then(data => data)
    setOptions([position])
    handleChange(position)
  }

  const GeolocationCSSClassnames = cs({
    GeolocationField: selectType === 'field',
    GeolocationField__error: hasError,
    GeolocationSelect: selectType === 'geolocation'
  })

  return (
    <div className="Geolocation">
      {getPosition && (
        <button
          aria-label={label}
          className="Geolocation__button"
          onClick={handleClick}
        >
          <Icon
            name={IconNames.Target}
            className="Geolocation__icon"
            width="80%"
            height="80%"
          />
        </button>
      )}
      <AsyncSelect
        aria-label={label}
        cacheOptions
        classNamePrefix={
          selectType === 'geolocation'
            ? 'GeolocationSelect'
            : 'GeolocationField'
        }
        onInputChange={onInputChange}
        className={GeolocationCSSClassnames}
        loadOptions={loadOptions}
        getOptionLabel={mapName}
        getOptionValue={selectType === 'geolocation' ? mapName : mapZipCode}
        options={options}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        onFocus={handleFocus}
        loadingMessage={loadingMessage}
        inputValue={inputValue}
      />
    </div>
  )
}
