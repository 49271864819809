import { TrackingUtilsInterface } from '@skoda-laurin/common'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import Routes from '../core/constants/Routes'
import { StepsEvent } from '../core/context/machines'
import { retrieve } from '../core/context/sync'
import { FUNDING_PLAN } from '../core/utils/constants'
import {
  formatCurrency,
  getLabelOptionsById,
  roundToHundreds
} from '../core/utils/dataFormat'
import TRACKING_VALUE from './constant'

interface StepName {
  isEngine: boolean
  isMileage: boolean
  isMyCar: boolean
  isTradingValue: boolean
}

class TrackingUtils implements TrackingUtilsInterface {
  public getPageNameFromPathName(pathname: string) {
    switch (pathname) {
      case Routes.Contact:
        return TRACKING_VALUE.PAGENAME.CONTACT_DETAIL_STEP_2
      case Routes.YourVehicle:
        return TRACKING_VALUE.PAGENAME.MY_CAR
      case Routes.Colors:
        return TRACKING_VALUE.PAGENAME.COLOR
      case Routes.Details:
        return TRACKING_VALUE.PAGENAME.MORE_DETAIL
      case Routes.Exterior:
        return TRACKING_VALUE.PAGENAME.EXTERNAL_CONDITION
      case Routes.Tyres:
        return TRACKING_VALUE.PAGENAME.TYRES
      case Routes.Options:
        return TRACKING_VALUE.PAGENAME.OPTIONS
      case Routes.Offers:
        return TRACKING_VALUE.PAGENAME.OFFERS
      case Routes.ContactAppointment:
        return TRACKING_VALUE.PAGENAME.RDV_DEALERSHIP_STEP_1
      case Routes.ContactAppointmentInformations:
        return TRACKING_VALUE.PAGENAME.RDV_DEALERSHIP_STEP_2
      case Routes.InServiceDate:
        return TRACKING_VALUE.PAGENAME.DATE_FIRST_REGISTRATION
      case Routes.Brand:
        return TRACKING_VALUE.PAGENAME.BRAND
      case Routes.Vehicle:
        return TRACKING_VALUE.PAGENAME.MODEL
      case Routes.EnergyAndModel:
        return TRACKING_VALUE.PAGENAME.ENGINE
      case Routes.EngineAndMileage:
        return TRACKING_VALUE.PAGENAME.MILEAGE
      case Routes.StartScreen:
        return TRACKING_VALUE.PAGENAME.HOMEPAGE
      case Routes.Dealer:
        return TRACKING_VALUE.PAGENAME.DEALER
      case Routes.Estimate:
        return TRACKING_VALUE.PAGENAME.MY_PLATE_NUMBER
      case Routes.PickYourVehicle:
        return TRACKING_VALUE.PAGENAME.SELECT_CAR
      case Routes.EstimateResult:
        return TRACKING_VALUE.PAGENAME.MY_ASSESSMENT
      case Routes.EstimateResultManual:
        return TRACKING_VALUE.PAGENAME.MY_ASSESSMENT
      case Routes.FinancialPlan:
        return TRACKING_VALUE.PAGENAME.FINANCING
      case Routes.Confirmation:
        return TRACKING_VALUE.PAGENAME.SUMMARY
      case Routes.CarNotEstimated:
        return TRACKING_VALUE.PAGENAME.UNKNOWN_VEHICLE
      default:
        return TRACKING_VALUE.NONE
    }
  }

  public getValuesFromPageName(pageName: string): StepName {
    switch (pageName) {
      case TRACKING_VALUE.PAGENAME.DATE_FIRST_REGISTRATION:
      case TRACKING_VALUE.PAGENAME.BRAND:
      case TRACKING_VALUE.PAGENAME.MODEL:
        return {
          isEngine: false,
          isMileage: false,
          isMyCar: false,
          isTradingValue: false
        }
      case TRACKING_VALUE.PAGENAME.MY_CAR:
        return {
          isEngine: false,
          isMileage: true,
          isMyCar: false,
          isTradingValue: false
        }
      case TRACKING_VALUE.PAGENAME.ENGINE:
      case TRACKING_VALUE.PAGENAME.COLOR:
      case TRACKING_VALUE.PAGENAME.MORE_DETAIL:
      case TRACKING_VALUE.PAGENAME.EXTERNAL_CONDITION:
      case TRACKING_VALUE.PAGENAME.TYRES:
      case TRACKING_VALUE.PAGENAME.OPTIONS: {
        return {
          isEngine: true,
          isMileage: true,
          isMyCar: true,
          isTradingValue: false
        }
      }
      default: {
        return {
          isEngine: true,
          isMileage: true,
          isMyCar: true,
          isTradingValue: true
        }
      }
    }
  }

  public getDataValues(currentData: any, pageName: string) {
    const { value, context } = currentData
    const {
      isEngine,
      isMileage,
      isMyCar,
      isTradingValue
    } = this.getValuesFromPageName(pageName)
    const {
      project,
      vehicle,
      manual,
      userInformations,
      estimation,
      selectedOffer,
      skodaVehicle
    } = context.demand

    const tradeInValue = estimation
      ? roundToHundreds(estimation.iPrixReprise)
      : 0
    const tradeInValueWithFormating = formatCurrency(tradeInValue)

    const carBrand =
      (manual && manual.brand) ||
      (vehicle && vehicle.s_veh_makname) ||
      TRACKING_VALUE.NONE
    const carModel =
      (manual && manual.model) ||
      (vehicle && vehicle.s_veh_modname2) ||
      TRACKING_VALUE.NONE
    const carEnergy =
      (manual && manual.energy) ||
      (vehicle && vehicle.s_veh_typtxtfueltypecd2) ||
      TRACKING_VALUE.NONE

    return {
      ...(value &&
        value.contact && { contact_way: value.contact || TRACKING_VALUE.NONE }),
      ...(isMyCar && {
        car: `${carBrand}|${carModel}|${carEnergy}`
      }),
      ...(manual && {
        date_first_registration:
          manual.dDateMec ||
          (vehicle && vehicle.d_veh_date_mec) ||
          TRACKING_VALUE.NONE
      }),
      ...(isEngine &&
        (manual || vehicle) && {
          engine:
            manual.energy ||
            (vehicle && vehicle.s_veh_typtxtfueltypecd2) ||
            TRACKING_VALUE.NONE
        }),
      ...(selectedOffer && {
        offer_selected: selectedOffer.id || TRACKING_VALUE.NONE
      }),
      ...(isTradingValue &&
        tradeInValueWithFormating !== 'NaN' && {
          trading_value: tradeInValueWithFormating || TRACKING_VALUE.NONE
        }),
      ...(isMileage &&
        manual && { mileage: manual.mileage || TRACKING_VALUE.NONE }),
      pagename: pageName,
      project_kind: project || TRACKING_VALUE.NONE,
      wish_car: skodaVehicle || TRACKING_VALUE.NONE,
      zipcode:
        (userInformations && userInformations.zip_code) || TRACKING_VALUE.NONE
    }
  }

  public getContactType(currentData: any) {
    const { value } = currentData
    return value.contact
  }

  public getVehicleConfigTracking(currentData: any) {
    if (currentData.context.vehicleConfigurator) {
      const { Vehicule } = currentData.context.vehicleConfigurator
      return {
        'market.Placeholder2': Vehicule.s_veh_vocordercode,
        'market.Placeholder3': Vehicule.s_veh_typname,
        parameter67: Vehicule.s_veh_typtxtfueltypecd2lbl
      }
    }
    return null
  }

  public getDealerInfoTracking(currentData: any) {
    const selecteDealer = get(currentData, 'context.demand.selectedDealer', {})
    if (!!currentData.context.demand.dealer || !isEmpty(selecteDealer)) {
      const dealer = currentData.context.demand.dealer || selecteDealer
      return {
        'dealer.City': dealer.s_pve_ville,
        'dealer.Region': dealer.s_pve_cp,
        'market.Placeholder1': dealer.s_pvm_code_atelier
      }
    }
    return null
  }

  public getMileage(currentData: any, pathname: string) {
    const step = this.StepNumber(currentData)
    if (!this.isTrackingTradeIn(pathname, step)) {
      return null
    }
    const manual = get(currentData, 'context.demand.manual', {})
    const kilometer = get(currentData, 'context.demand.kilometer', '')
    const valuesForm = get(currentData, 'context.demand.form.values', {})
    const mileage =
      manual.mileage || kilometer || valuesForm.mileage || undefined

    return {
      ...(mileage && { 'tc.Mileage': mileage })
    }
  }

  public getIPrixRepise(currentData: any) {
    const dataVehicle = get(currentData, 'context.demand.dataVehicle', {})
    const price = dataVehicle.iPrixReprise || ''
    const step = get(currentData, 'context.step', 0)
    return {
      ...(step > StepsEvent.TRADEIN && { 'fc.Price': price })
    }
  }

  public getFundingTracking(
    currentData: any,
    pathname: string,
    cash: boolean = false,
    nextBtnClick: boolean = false
  ) {
    const step = this.StepNumber(currentData)
    if (step < StepsEvent.FUNDING) {
      return null
    }
    const { plan } = currentData.context
    const payByCash = get(currentData, 'context.demand.payByCash', false)
    const dataFunding = get(
      currentData,
      ['context', 'demand', 'finance', 'dataFunding', plan],
      {}
    )
    const gotFundingDeal = !isEmpty(dataFunding)
    const isCash = (cash || !gotFundingDeal || payByCash) && !nextBtnClick

    const finance = get(currentData, 'context.demand.finance', {})
    const planKey = isCash
      ? 'cash'
      : findKey(FUNDING_PLAN, item => item === plan)
    const formFinance =
      Routes.FinancialPlan === pathname
        ? get(currentData, 'context.demand.form', {})
        : get(currentData, ['context', 'demand', 'finance', 'backup', plan], {})

    const values = !isCash && get(formFinance, 'values', {})
    const contributionAmount = values.contribution_amount || 0
    const fLoyer = get(
      finance,
      ['dataFunding', plan, 'aRetourFinancement', 'f_loyer'],
      0
    )
    const fPrestation = get(
      finance,
      ['dataFunding', plan, 'aRetourFinancement', 'f_prestations_cout_mensuel'],
      0
    )
    const finalPayment = !isCash && fLoyer + fPrestation
    return {
      'fc.Currency': 'EUR',
      ...(!!planKey && { parameter125: planKey.toLowerCase() }),
      ...(!!values.total_month && {
        parameter126: values.total_month
      }),
      ...(!!values.annual_kilometer && {
        parameter174: values.annual_kilometer
      }),
      ...(!isCash && {
        'fc.DownpaymentValue': contributionAmount
      }),
      ...(values.checkbox &&
        values.checkbox.length > 0 && {
          parameter176: getLabelOptionsById(dataFunding, values.checkbox)
        }),
      ...(!!finalPayment && { parameter172: finalPayment })
    }
  }

  public getVehicleTradeIn(currentData: any, pathname: string) {
    const step = this.StepNumber(currentData)
    if (!this.isTrackingTradeIn(pathname, step)) {
      return null
    }
    const vehicleManual = get(currentData, 'context.demand.manual.vehicle', {})
    const { vehSelect } = get(currentData, 'context.demand', {})
    const { form } = currentData.context.demand
    const vehicle = get(form, 'values.vehicle', '')
    if (isEmpty(vehicleManual) && isEmpty(vehSelect)) {
      return null
    }
    const car =
      vehicleManual.s_veh_modname2 ||
      vehSelect.s_veh_modname2 ||
      (pathname === Routes.Vehicle && vehicle) ||
      undefined

    const engine =
      vehicleManual.sTypNatcode ||
      vehSelect.s_veh_typnatcode ||
      (!!vehicle &&
        pathname === Routes.EngineAndMileage &&
        JSON.parse(vehicle).sTypNatcode) ||
      undefined

    const engineSaleText =
      vehicleManual.s_veh_typname ||
      vehSelect.s_veh_typname ||
      (!!vehicle &&
        pathname === Routes.EngineAndMileage &&
        JSON.parse(vehicle).s_veh_typname) ||
      undefined

    return {
      ...(car && { 'tc.Car': car }),
      ...(engine && { parameter121: engine }),
      ...(engineSaleText && { 'fc.Engine.Salestext': engineSaleText })
    }
  }

  public getPageNamePPCC() {
    const { pathname } = document.location
    return this.getPageNameFromPathName(pathname)
  }
  public StepNumber(currentData: any) {
    return get(currentData, 'context.step', 0)
  }
  public getEventNextBtnFromPathname(pathname: string) {
    switch (pathname) {
      case Routes.YourVehicle:
        return TRACKING_VALUE.NEXT_BTN.MY_CAR
      case Routes.Colors:
        return TRACKING_VALUE.NEXT_BTN.COLOR
      case Routes.Details:
        return TRACKING_VALUE.NEXT_BTN.MORE_DETAIL
      case Routes.Exterior:
        return TRACKING_VALUE.NEXT_BTN.EXTERNAL_CONDITION
      case Routes.Tyres:
        return TRACKING_VALUE.NEXT_BTN.TYRES
      case Routes.Options:
        return TRACKING_VALUE.NEXT_BTN.OPTIONS
      case Routes.InServiceDate:
        return TRACKING_VALUE.NEXT_BTN.DATE_FIRST_REGISTRATION
      case Routes.Brand:
        return TRACKING_VALUE.NEXT_BTN.BRAND
      case Routes.Vehicle:
        return TRACKING_VALUE.NEXT_BTN.MODEL
      case Routes.EnergyAndModel:
        return TRACKING_VALUE.NEXT_BTN.ENGINE
      case Routes.EngineAndMileage:
        return TRACKING_VALUE.NEXT_BTN.MILEAGE
      case Routes.Dealer:
        return TRACKING_VALUE.NEXT_BTN.DEALER
      case Routes.Estimate:
        return TRACKING_VALUE.NEXT_BTN.MY_PLATE_NUMBER
      case Routes.PickYourVehicle:
        return TRACKING_VALUE.NEXT_BTN.SELECT_CAR
      case Routes.EstimateResult:
        return TRACKING_VALUE.NEXT_BTN.MY_ASSESSMENT
      case Routes.EstimateResultManual:
        return TRACKING_VALUE.NEXT_BTN.MY_ASSESSMENT
      case Routes.Confirmation:
        return TRACKING_VALUE.NEXT_BTN.CONFIRMATION
      case Routes.FinancialPlan:
        return TRACKING_VALUE.NEXT_BTN.FINANCING
      default:
        return {}
    }
  }

  public isVehicleLayout(pathname: string) {
    return (
      pathname === Routes.YourVehicle ||
      pathname === Routes.PickYourVehicle ||
      pathname === Routes.EstimateResult ||
      pathname === Routes.EstimateResultManual
    )
  }

  public isVehicleSelect(pathname: string) {
    return (
      pathname === Routes.YourVehicle || pathname === Routes.PickYourVehicle
    )
  }

  public isManual(pathname: string) {
    return pathname.indexOf('saisie-manuelle') !== -1
  }

  public isTradeIn(pathname: string) {
    return this.isManual(pathname) || this.isVehicleLayout(pathname)
  }

  public isTrackingTradeIn(pathname: string, step: number) {
    return (
      (this.isTradeIn(pathname) && !this.isVehicleSelect(pathname)) ||
      step > StepsEvent.TRADEIN
    )
  }

  public reApplyOldDataLayer() {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    if (!window.dataLayerV2) {
      window.dataLayerV2 = []
    }
    const dataLayer = retrieve('dataLayer')
    if (dataLayer && dataLayer.length > 0) {
      window.dataLayer.push(...dataLayer)
      window.dataLayerV2 = [...window.dataLayerV2, ...dataLayer]
    }
  }
}

export default new TrackingUtils()
