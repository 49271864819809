import { assign, EventObject, StateNodeConfig } from 'xstate'
import Routes from '../../constants/Routes'
import { setContactLead } from '../../services/api'
export interface ConfirmationSchema {
  states: {
    idle: {}
    fetchContactType: {}
    emailChanging: {}
    email: {}
  }
}

type ConfirmationEventType = 'APPOINTMENT' | 'NEXT' | 'DONE' | 'FILLED'

export interface ConfirmationEvent extends EventObject {
  type: ConfirmationEventType
}

/**
 * This is the State Machine part related to the startWorkflow page
 */
// tslint:disable:object-literal-sort-keys
const node: StateNodeConfig<any, ConfirmationSchema, ConfirmationEvent> = {
  initial: 'idle',
  states: {
    idle: {
      meta: { path: Routes.Confirmation },
      entry: ['setStepConfirmation'],
      on: {
        APPOINTMENT: [
          {
            target: 'fetchContactType',
            cond: (_, { contactType }) => contactType !== 'email',
            actions: assign((ctx, { contactType }) => ({
              ...ctx,
              contactType
            }))
          },
          {
            target: 'emailChanging',
            cond: (_, { contactType }) => contactType === 'email',
            actions: assign((ctx, { contactType }) => ({
              ...ctx,
              contactType
            }))
          }
        ],
        NEXT: {
          target: 'fetchContactType',
          actions: assign(ctx => ({
            ...ctx,
            contactType: 'rdv'
          }))
        }
      }
    },
    fetchContactType: {
      meta: { loading: true },
      invoke: {
        src: ctx => setContactLead(ctx),
        onDone: [
          {
            target: '#app.demand.contactform',
            cond: ctx => ctx.contactType !== 'email',
            actions: assign((ctx, { data }) => ({
              demand: {
                ...ctx.demand,
                contactToken: data.token,
                contactId: data.id
              }
            }))
          },
          {
            target: 'email',
            cond: ctx => ctx.contactType === 'email'
          }
        ],
        onError: {
          target: 'idle',
          actions: 'consoleError'
        }
      }
    },
    emailChanging: {
      on: {
        DONE: {
          target: 'idle'
        },
        NEXT: {
          target: 'fetchContactType',
          actions: assign(({ demand }) => ({
            demand: {
              ...demand,
              userInformations: {
                ...demand.userInformations,
                email: demand.form.values.sEmailClient
              }
            }
          }))
        },
        FILLED: {
          actions: assign((ctx, { isValid, values }) => ({
            demand: { ...ctx.demand, form: { isValid, values } }
          }))
        }
      }
    },
    email: {
      on: {
        DONE: {
          target: 'idle'
        }
      }
    }
  }
}

export default node
