// tslint:disable object-literal-sort-keys

export const MIN_YEAR = 1983
export const UNSIGNED_NUMBER_MIN_VALUE = 0
export const ZIPCODE_MAX_VALUE = 99999

export const FUNDING_CODE = {
  CREDIT: 3,
  LEASING: 1,
  RENT: 753
}
export const FUNDING_PLAN = {
  CREDIT: 'cre',
  LEASING: 'loa',
  RENT: 'lld'
}

export const FUNDING_MATCH = {
  cre: 3,
  loa: 1
}

export const PLANS = [
  {
    name: 'loa',
    label: "Location avec option d'achat",
    code: '1'
  },
  {
    name: 'lld',
    label: 'Location longue durée',
    code: '2'
  },
  {
    name: 'cre',
    label: 'Crédit classique',
    code: '3'
  }
]

export const BREADCRUMP_STEP_INDEX = {
  CONFIGURATION: 0,
  DEALER: 1,
  TRADEIN: 2,
  FUNDING: 3,
  APPOINTMENT: 4
}
export const STEP_TITLE = [
  'Introduction',
  'Choix Concessionnaire',
  'Reprise',
  'Financement',
  'Finalisation'
]
export const LIST_TYPE_SEARCH = {
  Address: 'Adresse',
  Dealer: 'Concessionnaire'
}

export const COMMON_FIELD_NAME = ['birthday', 'profession']

export const CONFIGURATION_ID_MOCK = 'C6UT7LJ9'

export const DATE_FORMAT_FROM_API = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD-MM-YYYY'

export const URL_LEGAL_NOTICE =
  'https://www.skoda.fr/informations/mentions-legales'
export const URL_PRIVACY_POLICY =
  'https://www.skoda.fr/informations/politique-de-confidentialite'

export const INVALID_EMAIL_DOMAINS = [
  'prtnx.com',
  '20minutemail.com',
  '33mail.com',
  '34nm.com',
  'ahem.email',
  'ahem-email.com',
  'mail.ahem.email',
  'anonymbox.com',
  'dispostable.com',
  'emailisvalid.com',
  'emailsensei.com',
  'matapad.com',
  'armyspy.com',
  'cuvox.de',
  'dayrep.com',
  'einrot.com',
  'fleckens.hu',
  'gustr.com',
  'jourrapide.com',
  'rhyta.com',
  'superrito.com',
  'teleworm.us',
  '66.com',
  'gishpuppy.com',
  'guerrillamailblock.com',
  'harakirimail.com',
  'harakirimail.se',
  'haribu.net',
  'incognitomail.org',
  'jetable.org',
  'lazyinbox.com',
  'mailforspam.commfsa.ru',
  'email-jetable.fr',
  'mail-temporaire.fr',
  'mailtemporaire.fr',
  'mail-temporaire.com',
  'mailtemporaire.com',
  'easy-trash-mail.com',
  'easytrashmail.com',
  'email-jetable.biz.st',
  'padpub.co.cc',
  'jetable.co.cc',
  'email-jetable.co.tv',
  'mail-jetable.co.tv',
  'padpub.co.tv',
  'jetable.cz.cc',
  'email-jetable.cz.cc',
  'mail-jetable.cz.cc',
  'email-temporaire.cz.cc',
  'mail-temporaire.cz.cc',
  'mail7.io',
  'mailcatch.com',
  'nanozone.net',
  'mailHz.me',
  'mailHazard.com',
  'mailHazard.us',
  'zebins.com',
  'zebins.eu',
  'amail4.me',
  'mailimate.com1stw.comtonggen.com',
  'binkmail.com',
  'suremail.info',
  'safetymail.info',
  'MailNesia.com',
  'vipmailonly.info',
  'mailnull.com',
  'mailsac.com',
  'mailtemp.net',
  'av.mintemail.com',
  'mytempemail.com',
  'thankyou2010.com',
  'noclickemail.com',
  'nospamme.rs',
  'spam.destroyer.email',
  'randomail.net',
  'spambox.us',
  'spamfree24.org',
  'spamgourmet.com',
  'spamspot.com',
  'uroid.com',
  'TempEMail.net',
  'tempinbox.com',
  'DingBone.com',
  'FudgeRub.com',
  'LookUgly.com',
  'SmellFear.com',
  'lackmail.ru',
  'tempsky.com',
  'throwam.com',
  'trash-mail.com',
  'kurzpost.de',
  'objectmail.com',
  'proxymail.eu',
  'rcpt.at',
  'trash-mail.at',
  'trashmail.at',
  'trashmail.me',
  'wegwerfmail.de',
  'wegwerfmail.net',
  'wegwerfmail.org',
  'trashmail.ws',
  'yopmail.fr',
  'yopmail.net',
  'cool.fr.nf',
  'jetable.fr.nf',
  'nospam.ze.tc',
  'nomail.xl.cx',
  'mega.zik.dj',
  'speed.1s.fr',
  'courriel.fr.nf',
  'moncourrier.fr.nf',
  'monemail.fr.nf',
  'monmail.fr.nf',
  'mail.mezimages.net',
  'yopmail.com'
]

export const MOCK_OPTIONS = [
  { label: 'Décés', value: '123' },
  { label: 'véhicule de remplacement', value: '456' },
  { label: 'Entretien', value: '789' }
]

export const EXTERIOR_CAR_PART = {
  front: [
    {
      label: 'Rétroviseur Droit',
      prodId: 110,
      zoneId: 26
    },
    {
      label: 'Rétroviseur Gauche',
      prodId: 111,
      zoneId: 25
    },
    {
      label: 'Pavillon',
      prodId: 101,
      zoneId: 27
    },
    {
      label: 'Capot',
      prodId: 91,
      zoneId: 17
    },
    {
      label: 'Pare-chocs avant',
      prodId: 100,
      zoneId: 19
    }
  ],
  rear: [
    {
      label: 'Coffre',
      prodId: 92,
      zoneId: 18
    },
    {
      label: 'Pare-chocs arrière',
      prodId: 99,
      zoneId: 20
    }
  ],
  leftSide: [
    {
      label: 'Aile avant gauche',
      prodId: 88,
      zoneId: 5
    },
    {
      label: 'Porte avant gauche',
      prodId: 109,
      zoneId: 1
    },
    {
      label: 'Bas de caisse gauche',
      prodId: 90,
      zoneId: 9
    },
    {
      label: 'Porte arrière gauche',
      prodId: 107,
      zoneId: 2
    },
    {
      label: 'Aile arrière gauche',
      prodId: 86,
      zoneId: 6
    }
  ],
  rightSide: [
    {
      label: 'Aile avant droite',
      prodId: 85,
      zoneId: 7
    },
    {
      label: 'Porte avant droite',
      prodId: 108,
      zoneId: 3
    },
    {
      label: 'Bas de caisse droit',
      prodId: 89,
      zoneId: 10
    },
    {
      label: 'Porte arrière droite',
      prodId: 106,
      zoneId: 4
    },
    {
      label: 'Aile arrière droite',
      prodId: 87,
      zoneId: 8
    }
  ]
}
