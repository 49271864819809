import { retrieve } from '../context/sync'

const {
  REACT_APP_SKODA_CAR_CONFIGURATION,
  REACT_APP_SKODA_CAR_CONFIGURATION_PREPROD
} = process.env

export function getParam(id: string, location: any = document.location) {
  const { search } = location
  const chunks = search.substr(1).split('&')
  for (const chunk of chunks) {
    const [key, value] = chunk.split('=')
    if (key === id) {
      return decodeURIComponent(value)
    }
  }

  return undefined
}

export function convertParamsToObject(location: any = document.location) {
  const { search } = location
  if (search) {
    const params = search.slice(1)
    return JSON.parse(
      '{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      (key, value) => (key === '' ? value : decodeURIComponent(value))
    )
  }
  return undefined
}

export function appendConfigurationId(url: string) {
  const queryParams = retrieve('qp')
  if (queryParams && queryParams.configurationId) {
    return `${url}?configurationId=${queryParams.configurationId}`
  }
  return url
}

export function setUrlParams(url: string, params: any) {
  let instanceUrl = url
  for (const key in params) {
    if (!!key && !!params[key]) {
      const param = [key, params[key]].join('=')
      instanceUrl = instanceUrl.concat(`&${param}`)
    }
  }
  return instanceUrl
}

export function getConfigCarUrl() {
  const { host } = window.location
  if (host === 'v2-preprod-monprojet-skoda.as44099.com') {
    return REACT_APP_SKODA_CAR_CONFIGURATION_PREPROD
  }
  return REACT_APP_SKODA_CAR_CONFIGURATION
}
