import { FieldProps } from 'formik'
import React, { SyntheticEvent, useCallback } from 'react'

import RangeInput from '../inputs/RangeInput'
import { contributionFormat, listValueReturn } from './helpers'

interface Props extends FieldProps {
  label: string
  className?: string
  required?: boolean
  optionalMessage?: string
  contribution?: boolean
  min: string | number
  max: string | number
  listValue?: number[]
  kilometer?: boolean
}

export default function RangeField({
  form,
  field,
  label,
  max,
  min = 0,
  contribution = false,
  listValue = [],
  kilometer = false,
  ...props
}: Props) {
  const handleChange = useCallback(({ target }: SyntheticEvent) => {
    // limit the amount of chars in the field
    const { value } = target as HTMLInputElement
    let msg: string | number = value
    // format text if it is a registration
    if (contribution) {
      msg = contributionFormat(msg, max, min)
    }
    if (kilometer) {
      msg = Math.floor(Number(msg) / 1000) * 1000
    }
    if (listValue && listValue.length > 0) {
      msg = listValueReturn(Number(msg), listValue)
    }

    form.setFieldValue(field.name, msg)
  }, [])

  return (
    <RangeInput
      label={label}
      {...form}
      {...field}
      {...props}
      min={min}
      max={max}
      listValue={listValue}
      onChange={handleChange}
    />
  )
}
