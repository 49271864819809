import cs from 'classnames'
import React, { ReactNode } from 'react'
import { FixedBottom } from 'react-fixed-bottom'
import Button, { ButtonTypes } from '../buttons/Button'
import Icon, { IconNames } from '../icons/Icon'
import styles from './StepperLayout.module.scss'

interface Props {
  children: ReactNode
  numberOfSteps: number
  currentStep: number
  strings: {
    previousButton: string
    nextButton: string
    finishButton: string
  }
  isNextButtonDisabled?: boolean
  isPrevButtonDisabled?: boolean
  onClickPreviousButton?: (e: React.MouseEvent) => void
  onClickNextButton?: (e: React.MouseEvent) => void
  onClickFinishButton?: (e: React.MouseEvent) => void
  onClickCloseButton?: (e: React.MouseEvent) => void
  className?: string
  showingSteps?: boolean
  btnCloseClassName?: string
}

export default function StepperLayout({
  children,
  className,
  numberOfSteps,
  currentStep,
  strings,
  isNextButtonDisabled = false,
  isPrevButtonDisabled = false,
  onClickPreviousButton = () => undefined,
  onClickNextButton = () => undefined,
  onClickFinishButton = () => undefined,
  onClickCloseButton = () => undefined,
  showingSteps = true,
  btnCloseClassName
}: Props) {
  const progressPercent = (100 * currentStep) / numberOfSteps
  const isLastStep = currentStep === numberOfSteps

  function stringSteps() {
    if (showingSteps) {
      return `${strings.nextButton}&nbsp;${currentStep}&nbsp;/&nbsp;${numberOfSteps}`
    }
    return strings.nextButton
  }

  function renderNextButtonLabel() {
    let label
    if (isLastStep) {
      label = strings.finishButton
    } else {
      label = stringSteps()
    }
    return label.replace(/ /g, '&nbsp;')
  }

  const nextButtonLabel = renderNextButtonLabel()

  return (
    <section className={cs(styles.wrapper, className)}>
      <div
        role="button"
        className={cs(styles.closeButton, btnCloseClassName)}
        onClick={onClickCloseButton}
      >
        <Icon name="Cross" width="20px" height="20px" />
      </div>
      {children}
      <FixedBottom offset={0}>
        <nav className={styles.navbar}>
          <div className={styles.progressbar__background} />
          <div
            className={styles.progressbar}
            style={{ width: `${progressPercent}%` }}
          />
          <Button
            className={styles.navbarButton}
            label={strings.previousButton}
            types={ButtonTypes.GHOST}
            onClick={onClickPreviousButton}
            disabled={isPrevButtonDisabled}
            icon={IconNames.BackArrow}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: strings.previousButton.replace(/ /g, '&nbsp;')
              }}
            />
          </Button>
          <Button
            className={styles.navbarButton__next}
            label={nextButtonLabel}
            types={ButtonTypes.PRIMARY}
            onClick={!isLastStep ? onClickNextButton : onClickFinishButton}
            disabled={isNextButtonDisabled}
            icon={IconNames.RightArrow}
          >
            <span dangerouslySetInnerHTML={{ __html: nextButtonLabel }} />
          </Button>
        </nav>
      </FixedBottom>
    </section>
  )
}
