import cs from 'classnames'
import { ChildComponentProps } from 'google-map-react'
import React from 'react'

import Dealer from '../../../../trade-in/src/core/vo/Dealer'
import styles from './Marker.module.scss'

interface Props extends ChildComponentProps {
  dealer: Dealer
  isCardHover: boolean
  lat: number
  lng: number
}

export default function Marker({ $hover, isCardHover }: Props) {
  const markerClassNames = cs(styles.marker, {
    [styles.marker_hover]: $hover || isCardHover
  })

  return (
    <div className={styles.wrapper}>
      <div className={markerClassNames} />
    </div>
  )
}
