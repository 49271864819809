import { assign, EventObject, send, StateNodeConfig } from 'xstate'
import { FUNDING_CODE, FUNDING_PLAN } from './../../utils/constants'

import isEmpty from 'lodash/isEmpty'
import Routes from '../../constants/Routes'
import { getRefundData, setVehicleConfiguratorOffer } from '../../services/api'
import { getCommonValues, getDataFunding } from '../../utils/dataFormat'

const LOA_STATE = '#app.demand.finance.loa'
const LLD_STATE = '#app.demand.finance.lld'
const CRE_STATE = '#app.demand.finance.credit'
const CONFIRMATION = '#app.demand.confirmation'
export interface FinanceSchema {
  states: {
    idle: {}
    loa: {}
    lld: {}
    credit: {}
    fetchFunding: {}
    fetchDeal: {}
  }
}

type FinanceEventType =
  | 'PREVIOUS'
  | 'NEXT'
  | 'EDIT'
  | 'FILLED'
  | 'REDIRECT'
  | 'SELECT'
  | 'FETCH'
  | 'BACKUP'
  | 'REVERSE'
  | 'CASH'

export interface FinanceEvent extends EventObject {
  type: FinanceEventType
}

/**
 * This is the State Machine part related to the startWorkflow page
 */
// tslint:disable:object-literal-sort-keys
const node: StateNodeConfig<any, FinanceSchema, FinanceEvent> = {
  initial: 'idle',
  states: {
    idle: {
      meta: { path: Routes.FinancialPlan },
      entry: ['setStepFinance', send('REDIRECT')],
      on: {
        REDIRECT: [
          {
            target: LOA_STATE,
            cond: ctx =>
              ctx.plan === FUNDING_PLAN.LEASING &&
              ctx.availableFunding.hasOwnProperty(FUNDING_CODE.LEASING)
          },
          {
            target: LLD_STATE,
            cond: ctx => ctx.plan === FUNDING_PLAN.RENT
          },
          {
            target: CRE_STATE,
            cond: ctx =>
              ctx.plan === FUNDING_PLAN.CREDIT &&
              ctx.availableFunding.hasOwnProperty(FUNDING_CODE.CREDIT)
          }
        ]
      }
    },
    loa: {},
    lld: {},
    credit: {},
    fetchFunding: {
      meta: { loading: true },
      invoke: {
        src: ctx => getRefundData(ctx),
        onDone: [
          {
            target: 'idle',
            cond: (ctx, { data: dataFunding }) =>
              ctx.vehicleConfigurator &&
              !isEmpty(dataFunding.aRetourFinancementErreur),
            actions: [
              assign((ctx, { data: dataFunding }) => ({
                demand: {
                  ...ctx.demand,
                  finance: {
                    ...ctx.demand.finance,
                    dataFundingError:
                      dataFunding.aRetourFinancementErreur.s_message,
                    prix: {
                      ...ctx.demand.finance.prix,
                      [ctx.plan]: ctx.demand.dataVehicle || {}
                    }
                  }
                }
              })),
              /**
               * reverse form values when get-funding call error
               */
              send('REVERSE')
            ]
          },
          {
            target: 'idle',
            cond: (ctx, { data: dataFunding }) =>
              ctx.vehicleConfigurator &&
              dataFunding.aRetourFinancement &&
              !isEmpty(dataFunding.aRetourFinancement),
            actions: [
              assign((ctx, { data: dataFunding }) => ({
                demand: {
                  ...ctx.demand,
                  dataFunding,
                  dataFundingError: undefined,
                  finance: {
                    ...ctx.demand.finance,
                    dataFunding: {
                      ...ctx.demand.finance.dataFunding,
                      [ctx.plan]: dataFunding
                    },
                    dataFundingError: undefined,
                    prix: {
                      ...ctx.demand.finance.prix,
                      [ctx.plan]: ctx.demand.dataVehicle || {}
                    }
                  }
                }
              })),
              /**
               * Backup form values when get-funding call succeed
               */
              send('BACKUP')
            ]
          },
          {
            target: 'idle',
            cond: (ctx, { data: dataFunding }) =>
              ctx.vehicleConfigurator && !isEmpty(dataFunding),
            actions: assign(ctx => ({
              demand: {
                ...ctx.demand,
                finance: {
                  ...ctx.demand.finance,
                  dataFunding: {
                    ...ctx.demand.finance.dataFunding,
                    [ctx.plan]: {}
                  },
                  dataFundingError: undefined,
                  prix: {
                    ...ctx.demand.finance.prix,
                    [ctx.plan]: ctx.demand.dataVehicle || {}
                  }
                }
              }
            }))
          }
        ],
        onError: {
          target: 'idle',
          actions: 'consoleError'
        }
      }
    },
    fetchDeal: {
      meta: { loading: true },
      invoke: {
        src: ctx => setVehicleConfiguratorOffer(ctx),
        onDone: {
          target: CONFIRMATION,
          actions: assign((ctx, { data }) => ({
            demand: {
              ...ctx.demand,
              vehicleConfiguratorOffer: data,
              isFinance: true,
              payByCash: undefined,
              finance: {
                ...ctx.demand.finance,
                vehicleConfiguratorOffer: data
              }
            }
          }))
        },
        onError: {
          target: 'idle',
          actions: 'consoleError'
        }
      }
    }
  },
  on: {
    FILLED: {
      actions: assign(({ demand }, form) => ({
        demand: {
          ...demand,
          form,
          finance: {
            ...demand.finance
          }
        }
      }))
    },
    SELECT: {
      target: '#app.demand.finance',
      actions: assign((ctx, { plan }) => ({
        ...ctx,
        plan,
        demand: {
          ...ctx.demand,
          finance: {
            ...ctx.demand.finance,
            dataFundingError: undefined,
            plan
          }
        }
      }))
    },
    NEXT: [
      {
        target: '#app.demand.finance.fetchDeal',
        cond: ctx =>
          ctx.demand.finance.dataFunding &&
          !isEmpty(ctx.demand.finance.dataFunding[ctx.plan])
      },
      {
        target: CONFIRMATION,
        cond: ctx =>
          !ctx.demand.finance.dataFunding ||
          (ctx.demand.finance.dataFunding &&
            isEmpty(ctx.demand.finance.dataFunding[ctx.plan]))
      }
    ],
    FETCH: {
      target: '#app.demand.finance.fetchFunding'
    },
    BACKUP: {
      actions: assign(ctx => ({
        demand: {
          ...ctx.demand,
          finance: {
            ...ctx.demand.finance,
            prix: {
              ...ctx.demand.finance.prix,
              [ctx.plan]: ctx.demand.dataVehicle || {}
            },
            backup: {
              ...ctx.demand.finance.backup,
              common: getCommonValues(ctx.demand.form.values),
              [ctx.plan]: ctx.demand.form
            }
          }
        }
      }))
    },
    REVERSE: {
      actions: assign(({ demand, ...ctx }) => ({
        demand: {
          ...demand,
          form: getDataFunding(ctx)[2],
          finance: {
            ...demand.finance,
            /***
             * generated key value to reInitializeValue Formik incase dirty === true
             * https://github.com/formium/formik/issues/811
             */
            keyForm: Math.floor(Math.random() * 1000)
          }
        }
      }))
    },
    CASH: {
      target: CONFIRMATION,
      actions: assign(({ demand }) => ({
        demand: {
          ...demand,
          payByCash: true
        }
      }))
    }
  }
}

export default node
