import { Tracking } from '@skoda-laurin/common'
import axios, { AxiosResponse } from 'axios'
import debounce from 'debounce-promise'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import moment from 'moment'

import {
  Context,
  DemandContext,
  SKODA_BRAND_ID,
  StepsEvent,
  UserCategory
} from '../context/machines'
import { ConfirmationTypes } from '../context/machines/contact'
import { retrieve } from '../context/sync'
import {
  DATE_FORMAT_FROM_API,
  FUNDING_CODE,
  FUNDING_MATCH,
  FUNDING_PLAN,
  PLANS
} from '../utils/constants'
import {
  formatFundingType,
  getLabelOptionsById,
  getPrixValue,
  initialDataCRE,
  initialDataLOA,
  KeyValue,
  LabelValue,
  transformIntoLabelValue,
  transformVehiclesIntoLabelValue
} from '../utils/dataFormat'
import { setMBV } from '../utils/helpers'
import { appendConfigurationId, getConfigCarUrl } from '../utils/url'
import City from '../vo/City'
import Config from '../vo/Config'
import Dealer from '../vo/Dealer'
import Estimation from '../vo/Estimation'
import Media from '../vo/Media'
import Offer from '../vo/Offer'
import Options from '../vo/Options'
import SkodaVehicle from '../vo/SkodaVehicle'
import Vehicle from '../vo/Vehicle'
import VehicleElements, { DamageElement } from '../vo/VehicleElements'
const {
  NODE_ENV,
  REACT_APP_DEV_API_URL,
  REACT_APP_AUTH_TOKEN,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_PROVIDER
} = process.env

// a place to store JWT token

// avoid to call multiple times the same time
const deboundAxiosPost = debounce(axios.post, 500)

// setup the base API URL
axios.defaults.baseURL =
  NODE_ENV === 'development'
    ? REACT_APP_DEV_API_URL!
    : window.location.origin + '/api'

// requests interceptor to update JWT token and set it on headers
axios.interceptors.request.use(async config => {
  if (config.url!.includes('googleapis')) {
    return config
  }
  if (config.url!.indexOf('/login_check') !== -1) {
    // handle special case of refresh token request
    config.headers['x-auth-token'] = REACT_APP_AUTH_TOKEN!
    return config
  }

  const storedToken = localStorage.getItem('token')
  if (storedToken) {
    config.headers.common.Authorization = `Bearer ${storedToken}`
  }

  return config
})

axios.interceptors.response.use(
  response => response,
  async error => {
    // if token is invalid or expired
    if (error.response.data.code === 401 || error.response.data.code === 403) {
      const response = await deboundAxiosPost('/login_check')
      const token = response.data.token
      if (token) {
        localStorage.setItem('token', token)
        error.config.headers.Authorization = `Bearer ${token}`
        return axios.request(error.config)
      }
    }

    return Promise.reject(error)
  }
)

export async function getConfig(): Promise<Config> {
  const response = await axios.get('/ppcc/v1/config')
  return new Config(response.data.configuration)
}

export async function getInitialData(queryParams: any) {
  const [appConfiguration, vehicleConfigurator] = await Promise.all([
    getConfig(),
    getVehicleConfigurator(queryParams.configurationId)
  ])

  return {
    appConfiguration,
    vehicleConfigurator
  }
}

// TODO: check for missing configurationId case
export async function getVehicleConfigurator(configurationId: string) {
  if (!!configurationId) {
    const response = await postExternal(
      'get-vehicle-configurator',
      {
        s_marketing_code: configurationId,
        s_pvm_code_atelier: 'PPCCSKODA'
      },
      'external'
    )
    const { s_veh_vocordercode } = response.Vehicule
    setMBV(s_veh_vocordercode)
    return response
  }
  throw new Error()
}

export async function getFunding() {
  return await postExternal('get-available-funding', {}, 'external')
}
export async function getAvailableFunding(
  vehicleConfigurator: any,
  isDisplayRefunding: boolean = true
) {
  if (!isDisplayRefunding) {
    return { '1': {}, '3': {} } // making sure the type LOA and CRE exist
  }
  const fundingRaw = await postExternal(
    'get-available-funding',
    {
      aFinancement: {
        d_creation_financement: moment().format(DATE_FORMAT_FROM_API),
        i_fin_destination: 6,
        i_fin_marque_id: 28,
        i_sac_id: 1
      },
      aVehicule: vehicleConfigurator.Vehicule
    },
    'external'
  )
  return formatFundingType(Object.values(fundingRaw))
}

export async function getFundingFirstTime(
  funding: any,
  vehicleConfigurator: any,
  plan: string
) {
  const isLOA = plan === FUNDING_PLAN.LEASING
  const fundingPlan = isLOA
    ? funding[FUNDING_CODE.LEASING]
    : funding[FUNDING_CODE.CREDIT]
  const dataForm = isLOA
    ? initialDataLOA(fundingPlan)
    : initialDataCRE(fundingPlan)
  const refundId = get(fundingPlan, ['aFinancement', 'i_fin_id'], '')
  const fRemise = get(vehicleConfigurator, 'f_off_total_remises', '')
  const initialBackupValue = {
    annual_kilometer: dataForm.defaultKm,
    birthday: '',
    checkbox: '',
    contribution_amount: dataForm.defaultContribute,
    profession: '',
    total_month: dataForm.defaultMonth
  }
  const fundingData = await postExternal(
    'get-funding',
    {
      aFinancement: {
        d_creation_financement: moment().format(DATE_FORMAT_FROM_API),
        i_fin_destination: 6,
        i_fin_id: refundId,
        i_fin_marque_id: 28,
        i_sac_id: 1
      },
      aFinancementParametre: {
        aPrestation: [],
        d_naissance: '',
        f_apport: `${dataForm.defaultContribute}`,
        i_duree_mois: `${dataForm.defaultMonth}`,
        i_kilometrage: dataForm.defaultKm,
        i_profession: ''
      },
      aVehicule: {
        f_remise: fRemise,
        ...vehicleConfigurator.Vehicule
      }
    },
    'external'
  )
  return { fundingData, initialBackupValue }
}

export async function getRefundData(ctx: Context) {
  const { plan } = ctx
  const vehicleConfiguratorOffer = get(
    ctx,
    'demand.vehicleConfiguratorOffer',
    {}
  )
  const vehicleConfigurator = !isEmpty(vehicleConfiguratorOffer)
    ? vehicleConfiguratorOffer
    : get(ctx, 'vehicleConfigurator', {})
  const Vehicule = get(ctx, 'vehicleConfigurator.Vehicule', {})
  const dealerObject = get(vehicleConfigurator, 'f_off_total_remises', '')
  const values = get(ctx, 'demand.form.values', {})
  const fRePrise = get(ctx, 'demand.dataVehicle.iPrixReprise', undefined)
  const tradeInObject = {
    f_off_op_total_aide_reprise_ttc: get(
      vehicleConfigurator,
      'f_off_op_total_aide_reprise_ttc',
      ''
    ),
    f_reprise_aide: get(vehicleConfigurator, 'f_off_reprise_aide', '')
  }
  const fundingCode = get(FUNDING_MATCH, plan, '')
  const refundId = get(
    ctx,
    ['availableFunding', fundingCode, 'aFinancement', 'i_fin_id'],
    ''
  )
  const birthday = values.birthday
    ? moment(values.birthday, 'DD/MM/YYYY').format(DATE_FORMAT_FROM_API)
    : ''
  const profession = values.profession ? values.profession : ''
  return await postExternal(
    'get-funding',
    {
      aFinancement: {
        d_creation_financement: moment().format(DATE_FORMAT_FROM_API),
        i_fin_destination: 6,
        i_fin_id: refundId,
        i_fin_marque_id: 28,
        i_sac_id: 1
      },
      aFinancementParametre: {
        aPrestation: values.checkbox,
        d_naissance: birthday,
        f_apport: `${values.contribution_amount}`,
        f_reprise: fRePrise,
        i_duree_mois: `${values.total_month}`,
        i_kilometrage: values.annual_kilometer,
        i_profession: profession,
        ...(!!fRePrise && tradeInObject)
      },
      aVehicule: {
        f_remise: dealerObject,
        ...Vehicule
      }
    },
    'external'
  )
}

export async function getOffers(): Promise<Offer[]> {
  const response = await axios.get('/ppcc/v1/offers/list')
  return response.data.offers.map((offer: KeyValue<string>) => offer.offer)
}

export async function getCities(city: string): Promise<City[]> {
  let response: AxiosResponse<any>
  if (/\d+/g.test(city)) {
    response = await axios.get(`/v1/cities/?postal_codes=${city}`)
  } else {
    response = await axios.get(`/v1/cities/?name=${city}`)
  }
  return response.data
}

export async function getDealers(city: string): Promise<Dealer[]> {
  return await postExternal(
    'get-points-vente',
    {
      i_marque_id: SKODA_BRAND_ID,
      sAdresse: city
    },
    'external'
  )
}

export async function getAllDealers(): Promise<Dealer[]> {
  const demand = localStorage.getItem('demand')
  const emailUser = demand && JSON.parse(demand).userInformations
  return await postExternal(
    'get-points-vente',
    {
      iNombreMax: 1000,
      i_marque_id: SKODA_BRAND_ID,
      sAdresse: '',
      email: emailUser && emailUser.email ? emailUser.email : ''
    },
    'external'
  )
}

export async function getVehiclesByLicensePlate(
  licensePlate: string
): Promise<LabelValue[]> {
  const vehicle = await postExternal(
    'get-vehicule-by-immatriculation',
    { s_veh_immatriculation: licensePlate.replace(/-/g, '') },
    'external'
  )
  const typNatArr = vehicle.map((item: any) => item.s_veh_typnatcode)
  const promises: Array<Promise<LabelValue[]>> = typNatArr.map((item: any) => {
    return getMediaByLicense(item)
  })
  const media = await Promise.all(promises)
  return [vehicle, media]
}

export async function getMediaByLicense(
  typNatcode: number
): Promise<LabelValue[]> {
  const response = await postExternal(
    'get-media',
    { sVehTypNatcode: typNatcode },
    'external'
  )
  const media: any = {}
  media[typNatcode] = response[0]
  return media
}

export async function setVehicleConfiguratorOffer({
  vehicleConfigurator,
  demand,
  dynamicOffer,
  plan,
  step
}: Context): Promise<LabelValue[]> {
  const email = get(demand, 'userInformations.email', undefined)
  const vehicleConfiguratorOffer = get(demand, 'vehicleConfiguratorOffer', {})
  const Vehicule = !isEmpty(vehicleConfiguratorOffer)
    ? vehicleConfiguratorOffer.Vehicule
    : vehicleConfigurator.Vehicule
  const iPrixReprise = get(demand, 'dataVehicle.iPrixReprise', undefined)
  const iIdReprise = get(demand, 'dataVehicle.iIdReprise', undefined)
  const valueClient = get(dynamicOffer, 'value_client', undefined)
  const dataFunding = get(demand, ['finance', 'dataFunding', plan], undefined)
  const isDealerMap = step === StepsEvent.DEALER
  const sPvmCodeAtelier = isDealerMap
    ? get(demand, 'selectedDealer.s_pvm_code_atelier', undefined)
    : get(demand, 'dealer.s_pvm_code_atelier', undefined)
  const iAffId = get(demand, 'vehicleConfiguratorOffer.i_aff_id', undefined)
  const iOffId = get(demand, 'vehicleConfiguratorOffer.i_off_id', undefined)
  const iConId = get(demand, 'userInformations.i_con_id', undefined)

  const userInfo = iConId
    ? {
        i_con_id: iConId
      }
    : {
        ...(!!email && {
          Contact: {
            s_contact_email: email,
            s_contact_type: 'Particulier'
          }
        })
      }

  return await postExternal(
    'set-vehicle-configurator-offer',
    {
      Vehicule: isDealerMap
        ? omit(Vehicule, ['i_veh_id', 'i_cli_id'])
        : omit(Vehicule, 'i_veh_id'),
      b_reprise_scanvo: 0,
      f_off_reprise_aide: valueClient,
      f_off_reprise_total: iPrixReprise,
      i_off_statut: vehicleConfigurator.i_off_statut,
      i_rep_id: iIdReprise,
      insC43FinancementCompletDTO: dataFunding,
      s_pvm_code_atelier: sPvmCodeAtelier,
      ...(!isDealerMap && {
        i_aff_id: iAffId,
        i_off_id: iOffId
      }),
      ...userInfo
    },
    'external'
  )
}

export async function setDemandVehicle({
  vehSelect,
  kilometer,
  dealer,
  userInformations,
  iIdReprise
}: DemandContext): Promise<Estimation> {
  const iConId = userInformations && userInformations.i_con_id
  const userInfo = iConId
    ? {
        i_con_id: iConId
      }
    : {
        bGetReprise: true,
        sCiviliteClient: 'Monsieur',
        sEmailClient: userInformations && userInformations.email,
        sNomClient: ' ',
        sPrenomClient: ' '
      }
  const responseDemand = await postExternal(
    'set-demande',
    {
      aInfoComplementaire: [],
      aLDEDTO: [],
      aOptions: {},
      bDemandeAnonyme: false,
      bTVARecuperable: false,
      dDateMec: vehSelect && vehSelect.d_veh_date_mec,
      iKilometrage: kilometer,
      sCouleurExt: vehSelect && vehSelect.s_veh_couleur_exterieure,
      sVehTypNatcode: vehSelect && vehSelect.s_veh_typnatcode,
      s_pvm_code_atelier: dealer && dealer.s_pvm_code_atelier,
      ...userInfo
    },
    'external'
  )
  if (!!responseDemand.iIdReprise && !!iIdReprise) {
    killOldDemand(iIdReprise)
  }
  if (!responseDemand.iPrixReprise) {
    Tracking.sendPageView({
      'page.Name': 'error_api_prixreprise',
      'appweb.Name': '/'
    })
  }
  return responseDemand
}

export async function getDynamicOffer(): Promise<any> {
  const mbvStorage = retrieve('MBV')
  const mbv = mbvStorage.slice(0, 3)
  const response = await axios.get(`/ppcc/v1/offer/list?mbv=${mbv}`)
  return response.data[0]
}

export async function setDemandVehicleOffer(demand: DemandContext) {
  const [dataVehicle, offer] = await Promise.all([
    setDemandVehicle(demand),
    getDynamicOffer()
  ])
  return { dataVehicle, offer }
}

export async function getBrands(dDateMec: string): Promise<LabelValue[]> {
  const response = await postExternal(
    'd-date-mec-change',
    { dDateMec },
    'external'
  )
  return transformIntoLabelValue(response)
}

export async function getVehiclesFromBrand(
  brandId: number,
  dDateMec: string
): Promise<LabelValue[]> {
  const response = await postExternal(
    'i-veh-maknatcod-change',
    {
      dDateMec,
      iVehMaknat: brandId
    },
    'external'
  )
  return transformIntoLabelValue(response)
}

export async function getSkodaVehicles() {
  const response = await axios.get('/v1/vehicles/skoda')
  const skodaVehicles: SkodaVehicle[] = response.data

  const skodaVehiclesIndividual = skodaVehicles.filter(
    vehicle => vehicle.b2c_visible && vehicle.enabled
  )
  const skodaVehiclesProfessional = skodaVehicles.filter(
    vehicle => vehicle.b2b_visible && vehicle.enabled
  )

  const individual = transformVehiclesIntoLabelValue(skodaVehiclesIndividual)
  const professionnal = transformVehiclesIntoLabelValue(
    skodaVehiclesProfessional
  )

  return { individual, professionnal }
}

export async function getVehicleByRegistration(reg: string): Promise<Vehicle> {
  const response = await postExternal(
    'get-vehicule-by-immatriculation',
    {
      // Sanitize registration format
      s_veh_immatriculation: reg.replace(/-/g, '')
    },
    'external'
  )

  const vehicule: Vehicle = response.length ? response[0] : response

  // Fetch preview media of the vehicle for display convenience
  const media = await getMedia(
    vehicule.s_veh_typnatcode
      ? vehicule.s_veh_typnatcode
      : vehicule.sTypNatcode!
  )

  return {
    media: media.length ? media[0] : media,
    ...vehicule
  }
}

export async function getMedia(carId: string): Promise<Media[]> {
  return postExternal('get-media', { sVehTypNatcode: carId }, 'external')
}

export async function getVehicleElements(
  brandId: number,
  modelName: string
): Promise<KeyValue<VehicleElements>> {
  return postExternal(
    'get-liste-elements-specifiques',
    {
      i_veh_maknatcode: brandId,
      s_veh_modname2: modelName
    },
    'external'
  )
}

export async function getVehicleFilterList(
  sVehModname2: string,
  iVehMaknat: number,
  sFiltreLibelle: 's_veh_typtxtfueltypecd2' | 's_veh_typname2',
  dDateMec: string
): Promise<LabelValue[]> {
  const response = await postExternal(
    'get-modele-filtre',
    {
      dDateMec,
      iVehMaknat,
      sFiltreLibelle,
      sVehModname2
    },
    'external'
  )
  return transformIntoLabelValue(response)
}

export async function getVehicleMotorisation(
  sVehModname2: string,
  iVehMaknat: number,
  fuelType: string,
  vehicleFinish: string,
  dDateMec: string
): Promise<[]> {
  return postExternal(
    'a-filtres-change',
    {
      aFiltres: {
        s_veh_typname2: vehicleFinish,
        s_veh_typtxtfueltypecd2: fuelType
      },
      dDateMec,
      iVehMaknat,
      sVehModname2
    },
    'external'
  )
}

export async function getVehicleOptions(
  iVehType: number,
  dDateMec: string,
  iVehMaknat: number,
  sVehModname2: string,
  sVehTypNatcode: string
): Promise<Options[]> {
  return postExternal(
    'get-liste-equipements',
    {
      dDateMec,
      iVehMaknat,
      iVehType,
      sVehModname2,
      sVehTypNatcode
    },
    'external'
  )
}

export async function getCityFromCoordinates(latlng: string) {
  const response = await axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${REACT_APP_GOOGLE_API_KEY}`,
    {
      method: 'GET',
      responseType: 'json'
    }
  )
  return response.data
}

export async function setTryOutDemand({
  userInformations,
  manual,
  userCategory,
  dealer,
  dataVehicle,
  kilometer,
  iIdReprise
}: DemandContext): Promise<Estimation> {
  // '99999990' corresponding to an unspecified concession
  const dealerCode =
    NODE_ENV === 'production' && dealer ? dealer.s_pvm_code_atelier : '99999990'
  const iConId = userInformations && userInformations.i_con_id
  const userInfo = iConId
    ? {
        i_con_id: iConId
      }
    : {
        bGetReprise: true,
        sCiviliteClient: 'Monsieur',
        sEmailClient: userInformations!.email,
        sNomClient: ' ',
        sPrenomClient: ' '
      }
  const responseDemand = await postExternal(
    'set-demande',
    // tslint:disable:object-literal-sort-keys
    {
      dDateMec: manual!.dDateMec || manual!.vehicle!.d_veh_date_mec,
      sVehTypNatcode: manual!.vehicle!.s_veh_typnatcode
        ? manual!.vehicle!.s_veh_typnatcode
        : manual!.vehicle!.sTypNatcode,
      i_rep_id_remplacement:
        (dataVehicle && dataVehicle.iIdReprise) || undefined,
      sCouleurExt: manual!.sCouleurExt
        ? manual!.sCouleurExt
        : manual!.vehicle!.s_veh_couleur_exterieure,
      sCouleurInt: manual!.sCouleurInt,
      iKilometrage: manual!.mileage ? Number(manual!.mileage) : kilometer,
      bImport: manual!.bImport,
      bPremiereMain: manual!.bPremiereMain,
      bCarnetEntretient: manual!.bCarnetEntretient,
      bTVARecuperable: userCategory === UserCategory.PROFESSIONNEL,
      bDemandeAnonyme: false,
      aOptions: manual!.options || {},
      aLDEDTO: manual!.exteriorCondition
        ? manual!.exteriorCondition.filter((data: DamageElement) => !!data)
        : [],
      aInfoComplementaire: [],
      s_pvm_code_atelier: dealerCode.toString(),
      ...userInfo
    },
    'external'
  )
  if (!!responseDemand.iIdReprise && !!iIdReprise) {
    killOldDemand(iIdReprise)
  }
  if (!responseDemand.iPrixReprise) {
    Tracking.sendPageView({
      'page.Name': 'error_api_prixreprise',
      'appweb.Name': '/'
    })
  }
  return responseDemand
}

export async function setTryOutDemandOffer({
  ...demand
}: DemandContext): Promise<any> {
  const [dataVehicle, offer] = await Promise.all([
    setTryOutDemand({ ...demand }),
    getDynamicOffer()
  ])
  return { dataVehicle, offer }
}

export async function killOldDemand(iIdReprise: number) {
  return postExternal(
    'supprime-demande',
    {
      i_rep_id: iIdReprise,
      i_rep_statut: 7
    },
    'external'
  )
}

export async function setContactLead(ctx: Context) {
  const email = get(ctx, 'demand.userInformations.email', '')
  const { plan } = ctx
  const dealer = get(ctx, 'demand.dealer.s_pvm_code_atelier', '')
  const vehicles = retrieve('MBV').slice(0, 3)
  const type = get(ctx, 'contactType', 'rdv')
  const automaticVehicle = get(ctx, 'demand.vehSelect', {})
  const manualVehicle = get(ctx, 'demand.vehSelect', {})
  const sVehMakname =
    manualVehicle.sMarque || automaticVehicle.s_veh_makname || ''
  const model =
    manualVehicle.s_veh_modname2 || automaticVehicle.s_veh_modname2 || ''
  const typName =
    manualVehicle.s_veh_typname2 || automaticVehicle.s_veh_typname2 || ''
  const date = manualVehicle.dDateMec || automaticVehicle.d_veh_date_mec || ''
  const year = date.split('-')[0]
  const iPrixReprise = get(ctx, 'demand.dataVehicle.iPrixReprise', undefined)
  const payByCash = get(ctx, 'demand.payByCash', false)
  const fundingDeal = !!payByCash
    ? {}
    : get(ctx, ['demand', 'finance', 'dataFunding', plan], {})
  const isCash = isEmpty(fundingDeal)
  const planIndex = findIndex(PLANS, item => item.name === plan)
  const typeFinancement = isCash ? '' : PLANS[planIndex].label
  const fAnnualKilometer = isCash
    ? ''
    : get(
        ctx,
        ['demand', 'finance', 'backup', plan, 'values', 'annual_kilometer'],
        ''
      )
  const fDuration = isCash
    ? ''
    : get(
        ctx,
        ['demand', 'finance', 'backup', plan, 'values', 'total_month'],
        ''
      )
  const apporFinancement = isCash
    ? ''
    : get(
        ctx,
        ['demand', 'finance', 'backup', plan, 'values', 'contribution_amount'],
        ''
      )
  const checkbox = isCash
    ? []
    : get(ctx, ['demand', 'finance', 'backup', plan, 'values', 'checkbox'], [])
  const fLoyer = isCash
    ? ''
    : get(
        ctx,
        [
          'demand',
          'finance',
          'dataFunding',
          plan,
          'aRetourFinancement',
          'f_loyer'
        ],
        0
      )
  const fPrestation = isCash
    ? ''
    : get(
        ctx,
        [
          'demand',
          'finance',
          'dataFunding',
          plan,
          'aRetourFinancement',
          'f_prestations_cout_mensuel'
        ],
        0
      )
  const measurement = fLoyer + fPrestation
  const dataFundingPlan = get(
    ctx,
    ['availableFunding', PLANS[planIndex].code],
    {}
  )
  const options = isCash ? [] : getLabelOptionsById(dataFundingPlan, checkbox)
  const optionsFinancement = options.length > 0 ? options.join(', ') : ''
  const vehicleConfigurator = get(ctx, 'vehicleConfigurator', {})
  const modNameConfig = get(vehicleConfigurator, 'Vehicule.s_veh_modname2', '')
  const typeNameConfig = get(vehicleConfigurator, 'Vehicule.s_veh_typname2', '')
  const carNameConfig = `${modNameConfig} ${typeNameConfig}`
  const valueClient = get(ctx, 'dynamicOffer.value_client', '')
  const vehicleConfiguratorOffer = get(
    ctx,
    'demand.vehicleConfiguratorOffer',
    {}
  )
  const sIDCrypte = get(vehicleConfiguratorOffer, 'sIDCrypte', '')
  const iOffId = get(vehicleConfiguratorOffer, 'i_off_id', '')
  const urlCustom = `https://offre.webapp4you.eu/service/offre/visualisation?i_off_id=${sIDCrypte}`
  const configCarUrl = getConfigCarUrl()
  const configUrlAbsolute = appendConfigurationId(
    `${configCarUrl}/start-configuration`
  )
  const customObject = {
    url: urlCustom,
    configurationUrl: configUrlAbsolute
  }

  const response = await axios.post('/ppcc/v1/set-lead', {
    email,
    dealer,
    vehicles: [vehicles],
    bee2link_data: {
      type,
      marque_reprise: sVehMakname,
      modele_reprise: model,
      finition_reprise: typName,
      annee_reprise: year,
      prix_reprise: iPrixReprise,
      type_financement: typeFinancement,
      apport_financement: apporFinancement,
      mensualite_financement: measurement,
      options_financement: optionsFinancement,
      prix_configurate_vehicule: getPrixValue(vehicleConfigurator),
      bonus_reprise: valueClient,
      configurate_car_name: carNameConfig,
      i_off_id: iOffId,
      prix_remise: Math.round(
        get(vehicleConfigurator, 'f_off_total_remises', 0)
      )
    },
    ppcc_current_vehicle: model,
    ...(!isCash && {
      financing_annual_mileage: fAnnualKilometer,
      financing_duration: fDuration,
      financing_down_payment: apporFinancement,
      financing_funding_type: typeFinancement,
      financing_rate: measurement
    }),
    ...(!!sIDCrypte && {
      custom_info: JSON.stringify(customObject)
    })
  })
  return response.data
}

// tslint:disable-next-line:cognitive-complexity
export async function postLeadsTradIn({
  userInformations,
  vehicle,
  manual,
  selectedOffer,
  estimation,
  dealer,
  confirmationType,
  skodaVehicle,
  userCategory
}: DemandContext) {
  const actionType: any = {
    [ConfirmationTypes.APPOINTMENT]: 'create_lead',
    [ConfirmationTypes.EMAIL]: 'send_summary_by_mail',
    [ConfirmationTypes.PHONE]: 'call_me_back'
  }

  const action =
    actionType[confirmationType!] || actionType[ConfirmationTypes.EMAIL]

  // '99999990' corresponding to an unspecified concession
  const dealerCode =
    NODE_ENV === 'production' && dealer ? dealer.s_pvm_code_atelier : 99999990

  const queryParams = {
    ...retrieve('qp')
  }

  if (queryParams.utm_campaign) {
    queryParams.campaign = queryParams.utm_campaign
    delete queryParams.utm_campaign
  }

  const response = await axios.post(`/ppcc/v1/leads?action=${action}`, {
    trade_in_type: userCategory === UserCategory.PROFESSIONNEL ? 'b2b' : 'b2c',
    type: 'lead.type.trade_in',
    status: 'accepted',
    first_name: userInformations!.sPrenomClient,
    last_name: userInformations!.sNomClient,
    email: userInformations!.sEmailClient,
    title: userInformations!.sCiviliteClient === 'Madame' ? 'mme' : 'mr',
    phone_number: userInformations!.phone_number,
    dealer: dealerCode,
    city: userInformations!.city
      ? userInformations!.city.name
      : dealer!.s_pve_ville,
    zip_code: userInformations!.zip_code || dealer!.s_pve_cp,
    requested_at: moment().format('DD/MM/YYYY, hh:mm:ss'),
    requested_for: userInformations!.requested_for,
    trade_in_vehicle_brand:
      (vehicle && vehicle.s_veh_makname) || manual!.vehicle!.sMarque,
    trade_in_vehicle_model:
      (vehicle && vehicle.s_veh_modname2) || manual!.vehicle!.s_veh_modname2,
    trade_in_vehicle_year:
      vehicle && vehicle.d_veh_date_mec
        ? parseInt(vehicle.d_veh_date_mec.slice(0, 4), 10)
        : parseInt(manual!.dDateMec.slice(0, 4), 10),
    trade_in_vehicle_immatriculation:
      (vehicle && vehicle.s_veh_immatriculation) || null,
    trade_in_target_vehicle: skodaVehicle || null,
    trade_in_vehicle_misc: estimation!.iPrixReprise
      ? {
          exteriorCondition: manual!.exteriorCondition,
          sCouleurExt: manual!.sCouleurExt,
          sCouleurInt: manual!.sCouleurInt,
          bCarnetEntretient: manual!.bCarnetEntretient,
          bImport: manual!.bImport,
          bPremiereMain: manual!.bPremiereMain,
          tyres: manual!.tyres,
          options: manual!.options,
          energy: vehicle ? vehicle.s_veh_typtxtfueltypecd2lbl : manual!.energy
        }
      : {},
    trade_in_vehicle_partner_estimation: estimation!.iPrixReprise || null,
    trade_in_offer: selectedOffer || null,
    origin: 'Parcours Reprise',
    ...queryParams
  })
  return response.data
}

async function postExternal(
  method: string,
  options = {},
  provider = REACT_APP_PROVIDER
): Promise<any> {
  const storedTokenCaptcha = sessionStorage.getItem('tokenCaptcha')
  const recaptchaOption = {
    recaptcha_token: storedTokenCaptcha
  }
  options = { ...options, ...recaptchaOption }
  const response = await axios.post(
    `/ppcc/v1/external?provider=${provider}&handler=ppcc`,
    {
      method,
      options
    }
  )
  return response.data
}
