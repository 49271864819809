import { assign, EventObject, send, StateNodeConfig } from 'xstate'

import isEmpty from 'lodash/isEmpty'
import { StepsEvent } from '.'
import Routes from '../../constants/Routes'
import {
  getAllDealers,
  getCities,
  getDealers,
  getVehicleByRegistration,
  getVehiclesByLicensePlate,
  setDemandVehicleOffer,
  setTryOutDemandOffer,
  setVehicleConfiguratorOffer
} from '../../services/api'
import { Project } from '../../vo/Project'
const MANUAL_DEMAND = '#app.demand.manual'
const USER_INFORMATIONS = '#app.demand.userInformations'
const DEALER = '#app.demand.startWorkflow.dealer'
const ESTIMATE = '#app.demand.startWorkflow.estimate'
const STATE_DEMAND = '#app.demand.state'
const FINANCE = '#app.demand.finance'
const ERROR_FETCH_DEMAND = '#app.errorFetchDemand'
const ERROR_FETCH_DATA_ESTIMATE = '#app.errorDataEstimate'
export interface StartWorkflowSchema {
  states: {
    idle: {}
    searching: {}
    found: {}
    dealer: {
      states: {
        idle: {}
        found: {}
        fetchDealerDemand: {}
        fetchDealerDemandManual: {}
        fetchDealer: {}
      }
    }
    estimate: {
      states: {
        idle: {}
        fetchVehiclesByLicense: {}
        errorFetching: {}
      }
    }
    yourVehicle: {}
    pickYourVehicle: {}
    calculating: {
      states: {
        idle: {}
      }
    }
    estimateResult: {
      states: {
        idle: {}
        fetchResult: {}
      }
    }
    estimateResultManual: {
      states: {
        idle: {}
        fetchResult: {}
      }
    }
    unknown: {}
  }
}

type StartWorkflowEventType =
  | 'FIND'
  | 'DUNNO'
  | 'PREVIOUS'
  | 'NEXT'
  | 'EDIT'
  | 'FILLED'
  | 'DONE'
  | 'REDIRECT'
  | 'FETCH'
  | 'SELECT'
  | 'SKIP'
  | 'ABORT'
  | 'RESOLVE'
  | 'SET_USER_LOCATION'

export interface StartWorkflowEvent extends EventObject {
  type: StartWorkflowEventType
}

/**
 * This is the State Machine part related to the startWorkflow page
 */
// tslint:disable:object-literal-sort-keys
const node: StateNodeConfig<any, StartWorkflowSchema, StartWorkflowEvent> = {
  initial: 'idle',
  states: {
    idle: {
      meta: { path: Routes.StartScreen },
      on: {
        FIND: {
          target: 'searching',
          actions: assign(
            (ctx, { userCategory, mileage, postalCode, project, vehicle }) => ({
              demand: {
                userCategory,
                ...ctx.demand,
                manual: {
                  mileage
                },
                userInformations: {
                  zip_code: postalCode
                },
                project,
                skodaVehicle: vehicle
              }
            })
          )
        },
        DUNNO: {
          target: DEALER,
          actions: assign((ctx, { contactType, email }) => ({
            ...ctx,
            contactType,
            demand: {
              ...ctx.demand,
              userInformations: {
                email
              }
            }
          }))
        },
        DONE: {
          target: DEALER,
          actions: assign((ctx, { start_screen_email }) => ({
            demand: {
              ...ctx.demand,
              userInformations: {
                email: start_screen_email
              }
            }
          }))
        }
      }
    },
    searching: {
      meta: { loading: true },
      invoke: {
        src: (ctx, { s_veh_immatriculation }) =>
          Promise.all([
            getVehicleByRegistration(s_veh_immatriculation),
            getCities(ctx.demand.userInformations.zip_code),
            getDealers(ctx.demand.userInformations.zip_code)
          ]),
        onDone: {
          target: 'found',
          actions: assign((ctx, { data: [vehicle, city, dealers] }) => ({
            demand: {
              ...ctx.demand,
              vehicle,
              userInformations: {
                ...ctx.demand.userInformations,
                city: city[0]
              },
              dealer: dealers[0]
            }
          }))
        },
        onError: {
          target: 'unknown',
          actions: 'consoleError'
        }
      }
    },
    found: {
      meta: { path: Routes.YourCar },
      on: {
        PREVIOUS: 'idle',
        NEXT: [
          {
            target: USER_INFORMATIONS,
            cond: ({ demand }) =>
              !demand.userInformations.sEmailClient &&
              demand.project &&
              demand.project === Project.KNOW_PRICE,
            actions: send('FETCH_QUOTE', { delay: 400 })
          },
          {
            target: '#app.demand.state',
            actions: send('FETCH_QUOTE', { delay: 400 })
          }
        ],
        EDIT: MANUAL_DEMAND
      }
    },
    dealer: {
      initial: 'idle',
      states: {
        idle: {
          meta: { path: Routes.Dealer },
          invoke: {
            src: () => getAllDealers(),
            onDone: {
              target: 'found',
              actions: assign((ctx, { data: dealers }) => ({
                demand: {
                  ...ctx.demand,
                  selectedDealer: undefined,
                  dealers,
                  form: {
                    isValid: false,
                    values: {}
                  }
                }
              }))
            },
            onError: {
              target: '#app.demand.startWorkflow.unknown',
              actions: 'consoleError'
            }
          }
        },
        found: {
          meta: { path: Routes.Dealer },
          entry: ['setStepDealer'],
          on: {
            NEXT: [
              {
                target: 'fetchDealer',
                cond: ctx => !ctx.demand.isTradeIn
              },
              {
                target: '#app.demand.startWorkflow.dealer.fetchDealerDemand',
                cond: ctx => ctx.demand.isTradeIn && isEmpty(ctx.demand.manual)
              },
              {
                target:
                  '#app.demand.startWorkflow.dealer.fetchDealerDemandManual',
                cond: ctx => ctx.demand.isTradeIn && !isEmpty(ctx.demand.manual)
              }
            ]
          }
        },
        fetchDealerDemand: {
          meta: { loading: true },
          invoke: {
            src: ctx => setDemandVehicleOffer(ctx.demand),
            onDone: [
              {
                target: 'fetchDealer',
                cond: (ctx, { data }) =>
                  ctx.config.minEstimPrice < data.dataVehicle.iPrixReprise,
                actions: assign(({ demand }, { data }) => ({
                  demand: {
                    ...demand,
                    dataVehicle: data.dataVehicle,
                    dynamicOffer: data.offer,
                    iIdReprise: data.dataVehicle.iIdReprise,
                    manual: {},
                    userInformations: {
                      ...demand.userInformations,
                      i_con_id:
                        data.dataVehicle &&
                        data.dataVehicle.aReprise &&
                        data.dataVehicle.aReprise.i_con_id
                    }
                  }
                }))
              },
              {
                target: ERROR_FETCH_DEMAND,
                cond: (ctx, { data }) =>
                  ctx.config.minEstimPrice >= data.dataVehicle.iPrixReprise
              }
            ],
            onError: {
              target: ERROR_FETCH_DEMAND,
              actions: 'consoleError'
            }
          }
        },
        fetchDealerDemandManual: {
          meta: { loading: true },
          invoke: {
            src: ctx => setTryOutDemandOffer(ctx.demand),
            onDone: [
              {
                target: 'fetchDealer',
                cond: (ctx, { data }) =>
                  ctx.config.minEstimPrice < data.dataVehicle.iPrixReprise,
                actions: assign(({ demand }, { data }) => ({
                  demand: {
                    ...demand,
                    dataVehicle: data.dataVehicle,
                    dynamicOffer: data.offer,
                    userInformations: {
                      ...demand.userInformations,
                      i_con_id:
                        data.dataVehicle &&
                        data.dataVehicle.aReprise &&
                        data.dataVehicle.aReprise.i_con_id
                    }
                  }
                }))
              },
              {
                target: ERROR_FETCH_DEMAND,
                cond: (ctx, { data }) =>
                  ctx.config.minEstimPrice >= data.dataVehicle.iPrixReprise,
                actions: assign(({ demand }, { data }) => ({
                  demand: {
                    ...demand,
                    userInformations: {
                      ...demand.userInformations,
                      i_con_id:
                        data.dataVehicle &&
                        data.dataVehicle.aReprise &&
                        data.dataVehicle.aReprise.i_con_id
                    }
                  }
                }))
              }
            ],
            onError: {
              target: ERROR_FETCH_DEMAND,
              actions: 'consoleError'
            }
          }
        },
        fetchDealer: {
          meta: { loading: true },
          invoke: {
            src: ctx => setVehicleConfiguratorOffer(ctx),
            onDone: [
              {
                target: '#app.navigators',
                cond: ctx => !ctx.contactType || ctx.demand.lastStep,
                actions: assign((ctx, { data }) => ({
                  ...ctx,
                  jump: StepsEvent.TRADEIN,
                  demand: {
                    ...ctx.demand,
                    vehicleConfiguratorOffer: data,
                    isDealer: true,
                    dealer: ctx.demand.selectedDealer
                  }
                }))
              },
              {
                target: '#app.demand.confirmation.fetchContactType',
                cond: ctx => !!ctx.contactType && !ctx.demand.lastStep,
                actions: assign((ctx, { data }) => ({
                  demand: {
                    ...ctx.demand,
                    vehicleConfiguratorOffer: data,
                    isDealer: true,
                    dealer: ctx.demand.selectedDealer
                  }
                }))
              }
            ],
            onError: {
              target: 'found',
              actions: 'consoleError'
            }
          }
        }
      }
    },
    estimate: {
      initial: 'idle',
      states: {
        idle: {
          meta: { path: Routes.Estimate },
          entry: ['setStepTradeIn', 'resetEstimateContext'],
          on: {
            FETCH: {
              target: 'fetchVehiclesByLicense',
              actions: assign((ctx, { mileage }) => ({
                demand: {
                  ...ctx.demand,
                  kilometer: mileage
                }
              }))
            }
          }
        },
        fetchVehiclesByLicense: {
          meta: { loading: true },
          invoke: {
            src: ctx =>
              getVehiclesByLicensePlate(
                ctx.demand.form.values.s_veh_immatriculation
              ),
            onDone: [
              {
                target: '#app.demand.startWorkflow.yourVehicle',
                cond: (_, { data: [vehicle] }) => vehicle.length === 1,
                actions: assign((ctx, { data: [vehicle, media] }) => ({
                  demand: {
                    ...ctx.demand,
                    vehicleByLicense: vehicle,
                    mediaByLicense: media,
                    vehicleId: vehicle[0].s_veh_typnatcode,
                    vehSelect: vehicle[0]
                  }
                }))
              },
              {
                target: '#app.demand.startWorkflow.pickYourVehicle',
                cond: (_, { data: [vehicle] }) => vehicle.length > 1,
                actions: assign((ctx, { data: [vehicle, media] }) => ({
                  demand: {
                    ...ctx.demand,
                    vehicleByLicense: vehicle,
                    mediaByLicense: media,
                    vehicleId: -1
                  }
                }))
              }
            ],
            onError: {
              target: 'errorFetching',
              actions: 'consoleError'
            }
          }
        },
        errorFetching: {
          meta: { loading: false },
          on: {
            RESOLVE: {
              target: 'idle'
            }
          }
        }
      },
      on: {
        NEXT: {
          target: '#app.demand.finance',
          actions: assign(ctx => ({
            demand: {
              ...ctx.demand,
              vehicleId: undefined,
              vehSelect: {},
              vehicleByLicense: [],
              mediaByLicense: []
            }
          }))
        }
      }
    },
    yourVehicle: {
      meta: { path: Routes.YourVehicle },
      on: {
        NEXT: {
          target: '#app.demand.startWorkflow.calculating',
          cond: ({ demand }) =>
            demand.vehicleId &&
            demand.vehicleId !== -1 &&
            demand.vehicleId !== 'default'
        },
        PREVIOUS: ESTIMATE,
        EDIT: {
          target: MANUAL_DEMAND,
          actions: assign(({ demand }) => ({
            demand: {
              ...demand,
              manual: {},
              vehSelect: {},
              vehicleByLicense: [],
              mediaByLicense: [],
              vehicleId: undefined,
              kilometer: undefined
            }
          }))
        }
      }
    },
    pickYourVehicle: {
      meta: { path: Routes.PickYourVehicle },
      on: {
        NEXT: [
          {
            target: MANUAL_DEMAND,
            cond: ({ demand }) =>
              demand.vehicleId && demand.vehicleId === 'default',
            actions: assign(ctx => ({
              demand: {
                ...ctx.demand,
                vehSelect: {},
                manual: {},
                vehicleByLicense: [],
                mediaByLicense: [],
                vehicleId: undefined,
                kilometer: undefined
              }
            }))
          },
          {
            target: '#app.demand.startWorkflow.calculating',
            cond: ({ demand }) =>
              demand.vehicleId &&
              demand.vehicleId !== -1 &&
              demand.vehicleId !== 'default'
          }
        ],
        PREVIOUS: ESTIMATE
      }
    },
    calculating: {
      initial: 'idle',
      states: {
        idle: {
          meta: { loadingModal: true },
          invoke: {
            src: ctx => setDemandVehicleOffer(ctx.demand),
            onDone: [
              {
                target: '#app.demand.startWorkflow.estimateResult',
                cond: (ctx, { data }) =>
                  ctx.config.minEstimPrice < data.dataVehicle.iPrixReprise &&
                  !!data.dataVehicle.iPrixReprise,
                actions: assign(({ demand }, { data }) => ({
                  demand: {
                    ...demand,
                    dataVehicle: data.dataVehicle,
                    dynamicOffer: data.offer,
                    iIdReprise: data.dataVehicle.iIdReprise,
                    manual: {},
                    userInformations: {
                      ...demand.userInformations,
                      i_con_id:
                        data.dataVehicle &&
                        data.dataVehicle.aReprise &&
                        data.dataVehicle.aReprise.i_con_id
                    }
                  }
                }))
              },
              {
                target: ERROR_FETCH_DATA_ESTIMATE,
                cond: (_, { data }) =>
                  typeof data.dataVehicle.iPrixReprise === 'undefined' ||
                  data.dataVehicle.iPrixReprise === 1,
                actions: assign((ctx, { data }) => ({
                  demand: {
                    ...ctx.demand,
                    dataVehicle: data.dataVehicle,
                    dynamicOffer: data.offer,
                    iIdReprise: data.dataVehicle.iIdReprise,
                    manual: {},
                    isUndefinedEstimate: true,
                    userInformations: {
                      ...ctx.demand.userInformations,
                      i_con_id:
                        data.dataVehicle &&
                        data.dataVehicle.aReprise &&
                        data.dataVehicle.aReprise.i_con_id
                    }
                  }
                }))
              },
              {
                target: ERROR_FETCH_DEMAND,
                cond: (ctx, { data }) =>
                  ctx.config.minEstimPrice >= data.dataVehicle.iPrixReprise
              }
            ],
            onError: {
              target: ERROR_FETCH_DEMAND,
              actions: 'consoleError'
            }
          }
        }
      }
    },
    estimateResult: {
      initial: 'idle',
      states: {
        idle: {
          meta: { path: Routes.EstimateResult },
          entry: [
            assign(ctx => ({ demand: { ...ctx.demand, manual: {} } })),
            'setStepTradeIn',
            'resetIsTradeIn'
          ],
          on: {
            NEXT: {
              target: 'fetchResult'
            },
            PREVIOUS: [
              {
                target: STATE_DEMAND,
                cond: ctx =>
                  !!ctx.demand.vehSelect &&
                  !ctx.demand.manual.vehicle &&
                  Object.keys(ctx.demand.vehSelect).length > 0,
                actions: assign(({ demand }) => ({
                  demand: {
                    ...demand,
                    manual: {
                      ...demand.manual,
                      // vehicle on the estimateResult.fetchResult call would either be vehicle by manual workflow or vehicle by api get_by_immatriculation
                      vehicle:
                        !!demand.vehSelect && !demand.manual.vehicle
                          ? demand.vehSelect
                          : demand.manual.vehicle
                    }
                  }
                }))
              },
              {
                target: '#app.demand.state.fetchOptions',
                cond: ctx => !!ctx.demand.manual.vehicle,
                actions: assign(({ demand }) => ({
                  demand: {
                    ...demand,
                    manual: {
                      ...demand.manual
                    }
                  }
                }))
              }
            ],
            DONE: {
              target: FINANCE,
              actions: assign(ctx => ({
                ...ctx,
                demand: {
                  ...ctx.demand,
                  vehicleId: undefined,
                  kilometer: undefined,
                  dataVehicle: undefined,
                  vehSelect: undefined,
                  form: {
                    isValid: false,
                    values: undefined
                  },
                  vehicleByLicense: undefined,
                  mediaByLicense: undefined
                }
              }))
            }
          }
        },
        fetchResult: {
          meta: { loading: true },
          invoke: {
            src: ctx => setVehicleConfiguratorOffer(ctx),
            onDone: {
              target: FINANCE,
              actions: assign((ctx, { data }) => ({
                demand: {
                  ...ctx.demand,
                  vehicleConfiguratorOffer: data,
                  isTradeIn: true
                }
              }))
            },
            onError: {
              target: 'idle',
              actions: 'consoleError'
            }
          }
        }
      }
    },
    estimateResultManual: {
      initial: 'idle',
      states: {
        idle: {
          meta: { path: Routes.EstimateResultManual },
          entry: ['setStepTradeIn', 'resetIsTradeIn'],
          on: {
            NEXT: {
              target: 'fetchResult'
            },
            PREVIOUS: [
              {
                target: '#app.demand.state.fetchOptions',
                actions: assign(({ demand }) => ({
                  demand: {
                    ...demand,
                    manual: {
                      ...demand.manual
                    }
                  }
                }))
              }
            ],
            DONE: {
              target: FINANCE,
              actions: assign(ctx => ({
                ...ctx,
                demand: {
                  ...ctx.demand,
                  vehicleId: undefined,
                  kilometer: undefined,
                  dataVehicle: undefined,
                  vehSelect: undefined,
                  form: {
                    isValid: false,
                    values: undefined
                  },
                  vehicleByLicense: undefined,
                  mediaByLicense: undefined
                }
              }))
            }
          }
        },
        fetchResult: {
          meta: { loading: true },
          invoke: {
            src: ctx => setVehicleConfiguratorOffer(ctx),
            onDone: {
              target: FINANCE,
              actions: assign((ctx, { data }) => ({
                demand: {
                  ...ctx.demand,
                  vehicleConfiguratorOffer: data,
                  isTradeIn: true
                }
              }))
            },
            onError: {
              target: 'idle',
              actions: 'consoleError'
            }
          }
        }
      }
    },
    unknown: {
      meta: { path: Routes.CarUnknown },
      entry: assign(ctx => ({ demand: { ...ctx.demand, manual: undefined } })),
      on: {
        EDIT: MANUAL_DEMAND
      }
    }
  },
  on: {
    FILLED: {
      actions: assign(({ demand }, form) => ({
        demand: { ...demand, form }
      }))
    },
    DUNNO: {
      target: MANUAL_DEMAND,
      actions: assign(({ demand }) => ({
        demand: {
          ...demand,
          manual: {},
          vehSelect: {}
        }
      }))
    },
    SELECT: {
      actions: assign(({ demand }, { id: vehicleId, veh: vehSelect }) => ({
        demand: { ...demand, vehicleId, vehSelect }
      }))
    },
    SET_USER_LOCATION: {
      actions: assign(({ demand }, { userLocation }) => ({
        demand: {
          ...demand,
          currentUserLocation: userLocation
        }
      }))
    }
  }
}

export default node
