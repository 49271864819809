import { TrackingValueType } from '@skoda-laurin/common'

const TRACKING_VALUE: TrackingValueType = {
  ACTION: {
    BEGIN: 'begin',
    CONFIRM: 'confirm',
    CONTACT: 'contact',
    NEXT: 'next',
    RESET: 'reset',
    SKIP: 'skip'
  },
  BID: 995,
  ENV: {
    DEV: 'https://dev.monprojet.skoda.ddb.paris',
    PREPROD: 'v2-preprod-monprojet-skoda.as44099.com',
    PROD: 'https://prod_TBD.com'
  },
  EVENT: {
    CLICK: 'click'
  },
  EVENT_CATEROGY_PREFIX: 'Microsite.ppcc',
  EXTRA_DIMS: {
    'page.Group': 'ppcc'
  },
  LABEL: {
    REFINE: 'refine',
    UNKNOWN_VEHICLE: 'vehicule inconnu'
  },
  LEIB: 995,
  NEXT_BTN: {
    BRAND: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    COLOR: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    CONFIRMATION: {
      action: 'contact',
      label: 'etape_suivante',
      position: 'cta'
    },
    DATE_FIRST_REGISTRATION: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    DEALER: {
      action: 'next',
      label: 'next',
      position: 'form'
    },
    ENGINE: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    EXTERNAL_CONDITION: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    FINANCING: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    MILEAGE: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    MODEL: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    MORE_DETAIL: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    MY_ASSESSMENT: {
      action: 'next',
      label: 'next',
      position: 'cta'
    },
    MY_CAR: {
      action: 'next',
      label: 'see_estimation',
      position: 'cta'
    },
    MY_PLATE_NUMBER: {
      action: 'begin',
      label: 'begin',
      position: 'form'
    },
    OPTIONS: {
      action: 'next',
      label: 'finish',
      position: 'cta'
    },
    SELECT_CAR: {
      action: 'next',
      label: 'approve',
      position: 'cta'
    },
    TYRES: {
      action: 'next',
      label: 'next',
      position: 'cta'
    }
  },
  NONE: 'none',
  PAGENAME: {
    BE_RECALL: 'be_recall',
    BRAND: 'brand',
    COLOR: 'color',
    CONFIRMATION: 'confirmation',
    CONTACT_DETAIL_STEP_1: 'contact_details_step_1',
    CONTACT_DETAIL_STEP_2: 'contact_details_step_2',
    DATE_FIRST_REGISTRATION: 'date_first_registration',
    DEALER: 'dealer_selection',
    EMAIL_SUMMARY: 'email_summary',
    ENGINE: 'engine',
    EXTERNAL_CONDITION: 'external_condition',
    FINANCING: 'financing',
    HOMEPAGE: 'homepage',
    IMPORTED_VEHICLE: 'imported_vehicle',
    MILEAGE: 'mileage',
    MODEL: 'model',
    MORE_DETAIL: 'more_detail',
    MY_ASSESSMENT: 'my_assessment',
    MY_CAR: 'my_car',
    MY_PLATE_NUMBER: 'my_plate_number',
    OFFERS: 'offers',
    OPTIONS: 'options',
    PARTICULAR_VEHICLE: 'particular_vehicle',
    RDV_DEALERSHIP_STEP_1: 'rdv_dealership_step_1',
    RDV_DEALERSHIP_STEP_2: 'rdv_dealership_step_2',
    SELECT_CAR: 'select_car',
    SUMMARY: 'summary',
    TYRES: 'tyres',
    UNKNOWN_VEHICLE: 'unknown_vehicle',
    WRONG_INFORMATION: 'wrong_informations'
  },
  PAGE_LANGUAGE: 'fr-fr',
  POSITION: {
    CTA: 'cta',
    FORM: 'form',
    RIGHT_NAVBAR: 'right_navbar'
  },
  SITENAME: 'ppcc'
}

export default TRACKING_VALUE
