enum Routes {
  StartScreen = '/',
  Estimate = '/votre-vehicule-estimez',
  YourCar = '/votre-vehicule',
  YourVehicle = '/your-vehicle',
  PickYourVehicle = '/choose-your-vehicle',
  EstimateResult = '/estimate-result',
  EstimateResultManual = '/estimate-result-manual',
  FinancialPlan = '/financial-plan',
  CarUnknown = '/vehicule-inconnu',
  Contact = '/vos-coordonnees',
  Dealer = '/votre-concessionnaire',
  Confirmation = '/confirmation',
  InServiceDate = '/saisie-manuelle/date-de-mise-en-circulation',
  Brand = '/saisie-manuelle/marque',
  Vehicle = '/saisie-manuelle/modele',
  EnergyAndModel = '/saisie-manuelle/carburant-finition',
  EngineAndMileage = '/saisie-manuelle/motorisation-kilometrage',
  Colors = '/saisie-manuelle/couleur',
  Details = '/saisie-manuelle/precisions',
  Exterior = '/saisie-manuelle/etat-exterieur',
  Tyres = '/saisie-manuelle/etat-pneus',
  Options = '/saisie-manuelle/options',
  Offers = '/offres',
  ContactAppointment = '/contact/rdv-concessionnaire',
  ContactAppointmentInformations = '/contact/rdv-concessionnaire-coordonnees',
  ContactForm = '/make-an-appointment',
  ContactPhone = '/contact/etre-rappele',
  ContactEmail = '/contact/email-recapitulatif',
  ContactConfirmation = '/contact/confirmation',
  CarNotEstimated = '/estimation-inconnue'
}

export default Routes
